import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { apiURL } from "../../config/config";
import { connect } from "react-redux";
import { getAdmin } from "../../state/store";
import { Cookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheckAlt, faArrowRight, faCalendar, faAngleDown, faLockOpen, faMapMarkerAlt, faUser, faEnvelope, faPhone, faShip } from "@fortawesome/free-solid-svg-icons";
import Payments from "./Payments";
import { useTranslation } from "react-i18next";


function ReservationDetails(props) {
    const { t } = useTranslation("common");

    const cookies = new Cookies();

    const [message, setMessage] = useState("");
    const [showPriceCalc, setShowPriceCalc] = useState(false);
    const [showExtras, setShowExtras] = useState(false);
    const [showUser, setShowUser] = useState(false);

    const [showPayments, setShowPayments] = useState(false)

    useEffect(() => {
        if(message !== "") {
            setTimeout(() => {
                setMessage("");
                props.handleClose();
            }, 5000);
        }
    }, [message]);

    const handleBooking = async (optionId) => {
        try {
            await axios.post(
                apiURL + "booking/book",
                { id: optionId },
                {
                    headers: {
                        Authorization: "Bearer " + cookies.get("admintoken"),
                    },
                }
            );
            setMessage("A foglalás sikeresen megerősitve.");
            update();
        } catch (error) {
            console.log("erro", error);
            setMessage("A foglalás megerősitése sikertelen.");
        }
    };

    const handleStorno = async (optionId, optionUuId) => {
        try {
            axios.post(apiURL + "booking/storno", { id: optionId, uuid: optionUuId }, { headers: { Authorization: "Bearer " + cookies.get("admintoken") } });
            setMessage("Foglalás sikeresen sztornózva.");
            update();
        } catch (error) {
            console.log(error);
            if (error.response) {
                setMessage("A foglalás sztornózása sikertelen.");
            }
        }
    };

    function update() {
        props.updateReservations()
    }

    return (
        <div className="reservation-details">
            {props.details && (
                <Modal animation={false} dialogClassName="reservation-details-modal" show={props.show} onHide={props.handleClose}>
                    <Modal.Header className="reservation-header d-flex flex-wrap flex-column-reverse" closeButton>
                        <Modal.Title>Fogalalási részletek</Modal.Title>
                        <p>id: {props.details.id}</p>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="title">Alapadatok</h5>
                        <Row className="detail-box">
                            <Col>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faLockOpen} />{" "}
                                        </p>
                                    </Col>
                                    <Col sm={8} className="pl-0 d-flex align-items-center">
                                        <p>{props.details.reservationStatus}</p>
                                    </Col>
                                </Row>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faShip} />
                                        </p>
                                    </Col>
                                    <Col sm={8} className="pl-0 d-flex align-items-center">
                                        <p>{props.details.yachtName}</p>
                                    </Col>
                                </Row>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faCalendar} />
                                        </p>
                                    </Col>
                                    <Col sm={8} className="pl-0 d-flex align-items-center">
                                        <p>{props.details.periodFrom + " - " + props.details.periodTo}</p>
                                    </Col>
                                </Row>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        </p>
                                    </Col>
                                    <Col sm={8} className="pl-0 d-flex align-items-center">
                                        {props.details.locationFrom && (
                                            <p>
                                                {props.details.locationFrom.name.textEN} <FontAwesomeIcon icon={faArrowRight} /> {props.details.locationTo.name.textEN}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faUser} />
                                        </p>
                                    </Col>
                                    {props.details.client && (
                                        <Col sm={8} className="pl-0 d-flex align-items-center">
                                            <p>{props.details.client.surname + " " + props.details.client.firstname}</p>
                                        </Col>
                                    )}
                                </Row>
                                <Row className="detail-row">
                                    <Col sm={1} className="detail-icon">
                                        <p>
                                            <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                        </p>
                                    </Col>
                                    {props.details.paymentStatus && (
                                        <Col sm={8} className="pl-0 d-flex align-items-center">
                                            <p>{t(props.details.paymentStatus)} {props.details.paymentDate && <span className="payment-data-small">{"(" + props.details.paymentDate + " szám: " + props.details.paymentNumber +")"}</span>} </p>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <h5 className="title dropdown-title btn" onClick={() => setShowUser(!showUser)}>
                                {" "}
                                Megrendelő <FontAwesomeIcon icon={faAngleDown} /> 
                            </h5>
                        </Row>
                        {showUser && (
                            <Row className="detail-box">
                                <Col>
                                    <Row className="detail-row">
                                        <Col sm={1} className="detail-icon">
                                            <p>
                                                <FontAwesomeIcon icon={faUser} />
                                            </p>
                                        </Col>
                                        <Col sm={8} className="pl-0 d-flex align-items-center">{props.details.client && <p>{props.details.client.surname + " " + props.details.client.firstname}</p>}</Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={1} className="detail-icon">
                                            <p>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </p>
                                        </Col>
                                        <Col sm={8} className="pl-0 d-flex align-items-center">{props.details.client && props.details.client.email && <p>{props.details.client.email}</p>}</Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={1} className="detail-icon">
                                            <p>
                                                <FontAwesomeIcon icon={faPhone} />
                                            </p>
                                        </Col>
                                        <Col sm={8} className="pl-0 d-flex align-items-center">{props.details.client && props.details.client.phone && <p>{props.details.client.phone}</p>}</Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={1} className="detail-icon">
                                            <p>
                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            </p>
                                        </Col>
                                        <Col sm={8} className="pl-0 d-flex align-items-center">{props.details.client && props.details.client.zip && props.details.client.address && <p>{props.details.client.zip + " " + props.details.client.address}</p>}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <h5 className="title dropdown-title btn" onClick={() => setShowPriceCalc(!showPriceCalc)}>
                                {" "}
                                Ár kalkuláció <FontAwesomeIcon icon={faAngleDown} /> 
                            </h5>
                        </Row>
                        {showPriceCalc && (
                            <Row className="detail-box">
                                <Col>
                                    <Row className="detail-row">
                                        <Col sm={4} className="detail-name">
                                            <p>Lista ár: </p>
                                        </Col>
                                        <Col sm={8}>
                                            <p>{Math.round(props.details.priceListPrice) + " " + props.details.currency}</p>
                                        </Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={4} className="detail-name">
                                            <p>Ügyfél ár: </p>
                                        </Col>
                                        <Col sm={8}>
                                            <p>{Math.round(props.details.clientPrice) + " " + props.details.currency}</p>
                                        </Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={4} className="detail-name">
                                            <p>Ügynökségi ár: </p>
                                        </Col>
                                        <Col sm={8}>
                                            <p>{Math.round(props.details.agencyPrice) + " " + props.details.currency}</p>
                                        </Col>
                                    </Row>
                                    <Row className="detail-row">
                                        <Col sm={4} className="detail-name">
                                            <p>Ügynökség bevétel: </p>
                                        </Col>
                                        <Col sm={8}>
                                            <p>{Math.round(props.details.clientPrice - props.details.agencyPrice) + " " + props.details.currency}</p>
                                        </Col>
                                    </Row>
                                    {props.details.localizedFinalPrice && (
                                        <Row className="detail-row">
                                            <Col sm={4} className="detail-name">
                                                <p>Végösszeg: </p>
                                            </Col>
                                            <Col sm={8}>
                                                <p>{Math.round(props.details.localizedFinalPrice) + " " + props.details.currency}</p>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <h5 onClick={() => setShowExtras(!showExtras)} className="title dropdown-title btn">
                            Extrák <FontAwesomeIcon icon={faAngleDown} /> 
                            </h5>
                        </Row>
                        {showExtras && (
                            <Row>
                                <Col className="detail-box">
                                    <p className="detail-name">A bázison fizethető felszerelés: </p>
                                    <div className="detail-content">
                                        {props.details.additionalEquipment && !props.details.additionalEquipment.filter((equ) => equ.calculationType === "SEPARATE_PAYMENT").length ? (
                                            <p>Nincs bázison fizethető felszerelés.</p>
                                        ) : (
                                            props.details.additionalEquipment
                                                .filter((equ) => equ.calculationType === "SEPARATE_PAYMENT")
                                                .map((equ) => {
                                                    return (
                                                        <Row key={equ.id}>
                                                            <Col sm={4} className="">
                                                                {equ.name && <p>{equ.name.textEN}</p>}
                                                            </Col>
                                                            <Col sm={8}>
                                                                <p>
                                                                    {Math.round(equ.amountIsPercentage ? equ.amount * props.details.clientPrice : equ.amount)} {props.details.currency}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                        )}
                                    </div>
                                    <p className="detail-name">A bázison fizethető szolgáltatás: </p>
                                    <div className="detail-content">
                                        {props.details.services && !props.details.services.filter((serv) => serv.calculationType === "SEPARATE_PAYMENT").length ? (
                                            <p>Nincs bázison fizethető szolgáltatás.</p>
                                        ) : (
                                            props.details.services
                                                .filter((serv) => serv.calculationType === "SEPARATE_PAYMENT")
                                                .map((service) => {
                                                    return (
                                                        <Row key={service.id}>
                                                            <Col sm={4} className="">
                                                                <p>{service.name.textEN}</p>
                                                            </Col>
                                                            <Col sm={8}>
                                                                <p>{Math.round(service.amountIsPercentage ? service.amount * props.details.clientPrice : service.amount)}</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                        )}
                                    </div>
                                    <p className="detail-name">Előre fizetendő szolgáltatás: </p>
                                    <div className="detail-content">
                                        {props.details.services && !props.details.services.filter((serv) => serv.calculationType === "ADVANCE_PAYMENT").length ? (
                                            <p>Nincs előre fizetendő szolgáltatás.</p>
                                        ) : (
                                            props.details.services
                                                .filter((serv) => serv.calculationType === "ADVANCE_PAYMENT")
                                                .map((service) => {
                                                    return (
                                                        <Row key={service.id}>
                                                            <Col sm={4} className="">
                                                                <p>{service.name.textEN}</p>
                                                            </Col>
                                                            <Col sm={8}>
                                                                <p>{Math.round(service.amountIsPercentage ? service.amount * props.details.clientPrice : service.amount)}</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {props.details.reservationStatus === "OPTION" && (
                            <Row className="button-row">
                                <Col>
                                    <Button className="confirm" onClick={ ()=> props.details.paymentStatus === "PAYED" ? handleBooking(props.details.id) : setShowPayments(true)} >{props.details.paymentStatus === "PAYED" ? "Foglalás megerősítése" : "Fizetés megerősítése"}</Button>
                                </Col>
                                <Col>
                                    <Button className="cancel" variant="gold" onClick={() => handleStorno(props.details.id, props.details.uuid)}>
                                        Sztornó
                                    </Button>
                                </Col>
                            </Row>
                        )}

                        {message && <p className="feedback">{message}</p>}
                    </Modal.Footer>
                </Modal>
            )}
            <Payments
                id={props.details.id}
                paymentStatus={props.details.paymentStatus}
                showModal={showPayments}
                toggleShowModal={() => {setShowPayments( !showPayments)}} 
                update={update}
                details={props.details}
                setDetails={props.setDetails}
                />
        </div>
    );
}

const mapStateToProps = (state) => ({
    admin: getAdmin(state),
});

export default connect(mapStateToProps, null)(ReservationDetails);
