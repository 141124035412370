import React, {useState} from 'react';
import RegisterModal from './RegisterModal';

export default function RegistrationBanner() {

    const [showRegister, setShowRegister] = useState(false);
    const handleCloseRegister = () => setShowRegister(false);
    const handleShowRegister = () => {setShowRegister(true);};

    return(
        <div className="registration-banner">
            <div className="content">                
                <h2 className="main-title text-secondary">Regisztráljon!</h2>
                <h3 className="subtitle text-primary">Kapja meg a csak klubbtagoknak járó kedvezményeket.</h3>
                <button className="register-btn bg-warning text-secondary border-0" onClick={handleShowRegister}>Regisztráció</button>
            </div>
            <RegisterModal
                handleShowRegister={handleShowRegister}
                handleCloseRegister={handleCloseRegister}
                showRegister={showRegister}
            />
        </div>
    );
}