import React, { useEffect, useState } from "react"
import MDEditor from "@uiw/react-md-editor"
import { Button, Tab, Tabs, Row, Col } from "react-bootstrap"
import axios from "axios"
import { apiURL } from "../../config/config"
import { Cookies } from "react-cookie"

const feedbacksInitialStates = {
    "privacy-statement": "",
    "terms-of-use": "",
    contacts: "",
}

function TextEditors() {
    const cookies = new Cookies()

    const [contactsMarkDown, setContactsMarkdown] = useState("")
    const [privacyStatementMarkDown, setPrivacyStatementMarkdown] = useState("")
    const [termsOfUseMarkDown, setTermsOfUseMarkdown] = useState("")
    const [error, setError] = useState(feedbacksInitialStates)
    const [feedback, setFeedback] = useState(feedbacksInitialStates)

    useEffect(() => {
        fetchTexts()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchTexts() {
        try {
            const response = await axios.get(apiURL + "markdown", {
                headers: {
                    Authorization: "Bearer " + cookies.get("admintoken"),
                },
            })

            response.data.forEach((markdown) => {
                switch (markdown.name) {
                    case "privacy-statement":
                        setPrivacyStatementMarkdown(markdown.markDownText)
                        break
                    case "terms-of-use":
                        setTermsOfUseMarkdown(markdown.markDownText)
                        break
                    case "contacts":
                        setContactsMarkdown(markdown.markDownText)
                        break
                    default:
                        break
                }
            })
        } catch (error) {
            console.log("Failed to update the text")
        }
    }

    async function updateMarkDown(name, markDownText) {
        try {
            const response = await axios.patch(
                apiURL + "markdown",
                { name: name, markDownText: markDownText },
                {
                    headers: {
                        Authorization: "Bearer " + cookies.get("admintoken"),
                    },
                }
            )
            switch (name) {
                case "privacy-statement":
                    setPrivacyStatementMarkdown(response.data.markDownText)
                    break
                case "terms-of-use":
                    setTermsOfUseMarkdown(response.data.markDownText)
                    break
                case "contacts":
                    setContactsMarkdown(response.data.markDownText)
                    break

                default:
                    break
            }
            setFeedback({...feedback, [name]: "A szöveg sikeresen mentve"})

            setTimeout(() => {
                setFeedback(feedbacksInitialStates)
            }, 3000)
        } catch (error) {
            console.log("Failed to update the text")
            setError({ ...error, [name]: "A szöveg mentése sikertelen" })

            setTimeout(() => {
                setError(feedbacksInitialStates)
            }, 3000)
        }
    }
    return (
        <div className="text-editors">
            <Row className="mx-md-4">
                <h2>Szerkeszthető szövegek</h2>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="contacts" id="uncontrolled-tab-example" className="editor-tabs">
                        <Tab eventKey="contacts" title="Kapcsolat">
                            <MDEditor value={contactsMarkDown} preview="edit" onChange={setContactsMarkdown} height={500} visiableDragbar={false} highlightEnable={false} />
                            <div className="button-container">
                                <Button className="save-button" onClick={() => updateMarkDown("contacts", contactsMarkDown)}>
                                    Mentés
                                </Button>
                                <p className="error-message">{error["contacts"]}</p>
                                <p>{feedback["contacts"]}</p>
                            </div>

                            <MDEditor.Markdown className="preview" source={contactsMarkDown} />
                        </Tab>
                        <Tab eventKey="privacy-statement" title="Adatvédelmi nyilatkozat">
                            <MDEditor value={privacyStatementMarkDown} preview="edit" onChange={setPrivacyStatementMarkdown} height={500} visiableDragbar={false} highlightEnable={false} />
                            <div className="button-container">
                                <Button className="save-button" onClick={() => updateMarkDown("privacy-statement", privacyStatementMarkDown)}>
                                    Mentés
                                </Button>
                                <p className="error-message">{error["privacy-statement"]}</p>
                                <p>{feedback["privacy-statement"]}</p>
                            </div>

                            <MDEditor.Markdown className="preview" source={privacyStatementMarkDown} />
                        </Tab>
                        <Tab eventKey="profile" title="Felhasználási feltételek">
                            <MDEditor value={termsOfUseMarkDown} preview="edit" onChange={setTermsOfUseMarkdown} height={500} visiableDragbar={false} highlightEnable={false} />
                            <div className="button-container">
                                <Button className="save-button" onClick={() => updateMarkDown("terms-of-use", termsOfUseMarkDown)}>
                                    Mentés
                                </Button>
                                <p className="error-message">{error["terms-of-use"]}</p>
                                <p>{feedback["terms-of-use"]}</p>
                            </div>

                            <MDEditor.Markdown className="preview" source={termsOfUseMarkDown} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
}

export default TextEditors
