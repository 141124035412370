import axios from "axios";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer"
import Header from "../components/Header"
import { apiURL } from "../config/config";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { getIsLoggedIn, toggleIsLoggedIn } from "../state/store";

function PasswordReset(props) {

    const [password, setpassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [feedback, setFeedback] = useState('');

    const history = useHistory();
    const cookies = new Cookies();

    useEffect(() => {
        setTimeout(() => {
            setFeedback('')
        }, 4000)
    }, [feedback])

    async function resetPassword() {
        if(password.length < 7) {
            setFeedback('A jelszó legyen legalább 7 karakter hosszú');
            return
        }

        if(password === confirm) {
            let response = await axios.post(apiURL + "reset", { token:props.match.params.token, password:password });
            setFeedback('Jelszava sikeresen megváltozott !');
            props.toggleIsLoggedIn();
            cookies.set("token", response.data.jwt_token, { path: "/" });

            setTimeout(() => {
                history.push('/profile')
            }, 4000)
        }
        else {
            setFeedback('A jelszavak nem egyeznek.');
        }
    }
    
    return (
        <div>
            <div className="main-container">
                <Header />
            </div>
            <div className="password-reset d-flex flex-column justify-content-center">
                <div className="input-group">
                    <p>Új jelszó</p>
                    <input type="password" value={password} onChange={(evt) => setpassword(evt.target.value)}></input>
                </div>
                <div className="input-group">
                    <p>Új jelszó mégegyszer</p>
                    <input type="password" value={confirm} onChange={(evt) => setConfirm(evt.target.value)}></input>
                </div>
                {feedback.length ? <p>{feedback}</p> : null}
                <div>
                    <button disabled={!(password.length && confirm.length)} onClick={() => { resetPassword() }}>Új jelszó igénylése</button>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    login: getIsLoggedIn(state),
});

const mapDispatchToProps = { toggleIsLoggedIn };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
