import React, {useState, useEffect} from "react";
import { Row } from "react-bootstrap";
import axios from "axios";
import { apiURL } from "../../config/config"
import ReservationDetails from "./ReservationDetails"
import { Cookies } from "react-cookie"
import Table from "./adminTable";
import moment from "moment";

export default function BookedReservations(props) {
    const cookies = new Cookies()
    const [bookedReservations, setBookedReservations] = useState([])
    const [details, setDetails] = useState({})

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [ handleUpdate, setHandleUpdate ] = useState(true);

    const handleClose = () => setShowDetailsModal(false);
    const handleShow = (data) => {
        setDetails(data)
        setShowDetailsModal(true)
    };

    useEffect(() => {
        if(handleUpdate) {
            axios.get(apiURL + "booking/booked", {
                headers: {
                'Authorization': 'Bearer ' + cookies.get("admintoken")
                }
            }).then(response =>{
                setBookedReservations(response.data)
            })
            setHandleUpdate(false);
        }
    }, [handleUpdate])

    //Setup table
    const columns = React.useMemo(() => [
        {
            Header: 'ID',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: 'Hajó',
            accessor: 'yachtName',
        },
        {
            Header: 'Felhasználó',
            accessor: (originalRow, rowIndex) => {
                if(originalRow.client) {
                    return originalRow.client.surname + " " + originalRow.client.firstname
                } 
                else {
                    return "Nem található"
                }
            },
        },
        {
            Header: 'Kezdete',
            accessor: (originalRow, rowIndex) => {
                let date = moment(originalRow.periodFrom, 'DD-MM-YYYY HH:mm')
                return moment(date).format('YYYY-MM-DD');
            },
            sortType: (rowA, rowB, columnId, desc) => {
                let a =  moment(rowA.original.periodFrom, 'DD-MM-YYYY HH:mm');
                let b = moment(rowB.original.periodFrom, 'DD-MM-YYYY HH:mm');
                if(a > b) {
                    return 1
                }
                else {
                    return -1
                }
            }
        },
        {
            Header: 'Vége',
            accessor: (originalRow, rowIndex) => {
                let date = moment(originalRow.periodTo, 'DD-MM-YYYY HH:mm')
                return moment(date).format('YYYY-MM-DD');
            },
            sortType: (rowA, rowB, columnId, desc) => {
                let a =  moment(rowA.original.periodFrom, 'DD-MM-YYYY HH:mm');
                let b = moment(rowB.original.periodFrom, 'DD-MM-YYYY HH:mm');
                if(a > b) {
                    return 1
                }
                else {
                    return -1
                }
            },
        },
        {
            Header: 'Foglalás időpontja',
            id: "createdDate",
            accessor: (originalRow, rowIndex) => {
                let date = moment(originalRow.createdDate, 'DD-MM-YYYY HH:mm')
                return moment(date).format('YYYY-MM-DD');
            },
        },
        {
            Header: 'Státusz',
            accessor: 'reservationStatus',
        },
    ],[])

    const data = [ ...bookedReservations ]

    return (
        <div className="reservations text-primary">
            <Row className="mx-md-4">
                <h2>Foglalások</h2>
            </Row>
            <Row className="mx-md-4">
                <Table data={data} columns={columns} handleShow={handleShow}/>
            </Row>
            <ReservationDetails details={details} show={showDetailsModal} handleClose={handleClose} handleShow={handleShow} />
        </div>
    )
}