import React from 'react';
import { useHistory } from 'react-router-dom';
import {  Button, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { getAdmin, getIsLoggedInAdmin, toggleIsLoggedInAdmin } from "../../state/store";
import { Cookies } from "react-cookie";


function AdminNavbar(props) {
  const history = useHistory();

  const cookies = new Cookies()

  const handleLogout = () => {
    cookies.remove("admintoken", { path: '/admin'})
    cookies.remove("admin", { path: '/admin'})

    props.toggleIsLoggedInAdmin()
    history.push('/admin');
};


  return (
    <Row className='adminnavbar mb-4 px-4 d-flex align-items-center text-center justify-content-center justify-content-md-end'>
      {props.admin && (
        <p href='#' className='text-primary d-flex align-items-center justify-content-center '>
          Bejelentkezve: {props.admin.username}
        </p>
      )}
      {props.login && (
        <Button variant='primary' className='ml-4' onClick={handleLogout}>
          Kijelentkezés
        </Button>
      )}
    </Row>
  );
}


const mapStateToProps = (state) => ({
  login:getIsLoggedInAdmin(state),
  admin: getAdmin(state)
});

const mapDispatchToProps = {toggleIsLoggedInAdmin}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
