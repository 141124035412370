import React, { useState, useEffect } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { Row, Col } from "react-bootstrap"
import { Cookies } from "react-cookie"
import { apiURL } from "../config/config"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import UserReservations from "../components/user/UserReservations"

const displayedDatasInit = {
    surname: false,
    firstname: false,
    country: false,
    city: false,
    address: false,
    zip: false,
    email: false,
    phone: false,
}
const newDataStateInit = {
    surname: "",
    firstname: "",
    country: "",
    city: "",
    address: "",
    zip: "",
    email: "",
    phone: "",
}

export default function Profile() {
    const { t } = useTranslation("common")

    const cookies = new Cookies();
    const [user, setUser] = useState({});
    const [newData, setNewData] = useState(newDataStateInit);
    const [displayedDatas, setdisplayedDatas] = useState(displayedDatasInit);

    const [ reservations, setReservations ] = useState([]); 

    useEffect(() => {
        getUserData();
    }, [newData])

    async function getUserData() {
        let user = await axios.get(apiURL + "user", { headers: { Authorization: "Bearer " + cookies.get("token")}});
        setUser(user.data);
        let reservations = await axios.post(apiURL + "user/reservations", {email:user.data.email}, { headers: { Authorization: "Bearer " + cookies.get("token")}});
        
        setReservations(reservations.data)

    }

    const handleSubmit = async (e) => {
        const fieldname = e.target.getAttribute("name")
        try {
            await axios.patch(apiURL + "user", { [fieldname]: newData[fieldname] }, { headers: { Authorization: "Bearer " + cookies.get("token") } })
            setNewData({ ...newData, [fieldname]: "" })
        } catch (error) {
        }
    }

    const handleChange = (e) => {
        setNewData({ ...newData, [e.target.name]: e.target.value })
    }

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="profile-data-container">
                <Row>
                    <Col md={6} className="userdatas mb-4">
                    <h2 className="main-title text-warning text-center text-lg-left">{t("Felhasználói adatok")}</h2>
                        {Object.entries(user).map(([fieldKey, value]) => {
                            if (Object.keys(displayedDatas).includes(fieldKey)) {
                                return (
                                    <Row key={fieldKey} className="userdatas-row justify-content-center justify-content-md-start">
                                        <Col sm={3} md={12} lg={4}>
                                            <p className="key text-primary">{t(fieldKey)}</p>
                                        </Col>
                                        <Col xs={10} sm={5} md={10} lg={6} className="d-flex align-items-center">
                                            <p className="value text-primary">{value}</p>
                                        </Col>
                                        <Col xs={2} sm={1} md={2} lg={2} className="d-flex align-items-center">
                                            <FontAwesomeIcon
                                                className="pencil-alt text-primary"
                                                icon={faEdit}
                                                onClick={() => {
                                                    setdisplayedDatas({ ...displayedDatas, [fieldKey]: !displayedDatas[fieldKey] })
                                                }}
                                            />
                                        </Col>
                                        {displayedDatas[fieldKey] && (
                                            <Col sm={9} md={12} className="edit-container d-flex align-items-end">
                                                <input type="text" placeholder={t(fieldKey)} name={fieldKey} id="" value={newData[fieldKey]} onChange={handleChange} />
                                                <p className="btn bg-warning" name={fieldKey} onClick={handleSubmit}>Módosít</p>
                                            </Col>
                                        )}
                                    </Row>
                                )
                            }
                        })}
                    </Col>
                    <Col md={6}>
                        <h2 className="main-title text-warning text-center text-lg-left">{t("Foglalásaim")}</h2>
                        {reservations.map(reservation => {
                            return(
                                <UserReservations key={reservation.id} reservationData={reservation}/>
                            )
                        })}
                    </Col>
                </Row>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}
