import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import './styles/styles.scss';

//screens
import Homepage from './screens/Homepage';
import Search from './screens/Search';
import Details from './screens/Details';
import AdminLogin from './screens/admin/AdminLogin';
import AdminProfile from './screens/admin/AdminProfile';

//redux
import createStore from './state/store';
import { Provider } from 'react-redux';

//cookies
import { CookiesProvider } from "react-cookie"

//date pucker
// import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import PrivateRoute from './_helper/privateRoute';
import Profile from './screens/Profile';
import HowItWorks from './screens/HowItWorks';
import Contacts from './screens/Contacts';
import UserReservationDetails from './components/user/UserReservationDetails';
import PasswordReset from './screens/PasswordReset';
import PrivacyStatemant from './screens/PrivacyStatemant';
import TermsOfUse from './screens/TermsOfUse';

export default function App() {
    const store = createStore();

    return (
        <CookiesProvider>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/profile/reservation/:id" component={UserReservationDetails} />
                        <Route exact path='/search' component={Search} />
                        <Route exact path='/howitworks' component={HowItWorks} />
                        <Route exact path='/contacts' component={Contacts} />
                        <Route exact path='/privacy-statement' component={PrivacyStatemant} />
                        <Route exact path='/terms-of-use' component={TermsOfUse} />
                        <Route exact path='/details' component={Details} />
                        <PrivateRoute exact path='/admin/profile' component={AdminProfile} />
                        <Route path="/admin/profile/*">
                            <Redirect to="/admin/profile" />
                        </Route>
                        <Route exact path='/reset/:token' component={PasswordReset} />
                        <Route exact path='/admin' component={AdminLogin} />
                        <Route exact path='/' component={Homepage} />
                    </Switch>
                </Router>
            </Provider>
        </CookiesProvider>
    );
}
