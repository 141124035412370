import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"


const initUserdata = {
    name: "",
    email: "",
};

export default function Newsletter() {
    const { t } = useTranslation("common")

    const [userdata, setUserdata] = useState(initUserdata);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 3000);
    }, [errorMessage]);

    const handleSubmit = (e) => {
        if (validator()) {
            e.preventDefault();
        }
    };

    const validator = () => {
        let isValid = true;
        if (!(userdata.name !== "" && userdata.email !== "")) {
            setErrorMessage("Minden mező kitöltése kötelező");
            isValid = false;
        } else if (!userdata.email.includes("@")) {
            setErrorMessage("Az email nem megfelelő");
            isValid = false;
        }
        return isValid;
    };

    return (
        <div className="newsletter">
            <div className="content text-primary">
                <h2 className="main-title text-secondary">{t("Íratkozzon fel hírlevelünkre!")}</h2>
                <h3 className="subtitle">{t("Tudja meg az elsők között a legfrissebb hajózással kapcsolatos híreket.")}</h3>
                <form className="subscribe-form">
                    <input type="text" placeholder={t("Név")} name="name" value={userdata.name} onChange={(e) => setUserdata({ ...userdata, [e.target.name]: e.target.value })} />
                    <input type="email" placeholder="E-mail" name="email" value={userdata.email} onChange={(e) => setUserdata({ ...userdata, [e.target.name]: e.target.value })} />
                    <div className="checkbox-container d-flex align-items-center">
                        <input type="checkbox" id="checkbox" hidden />
                        <label htmlFor="checkbox" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                        <label htmlFor="checkbox" className="checkbox-label text-secondary mb-0">
                            {t("Elfogadom a felhasználási feltételeket.")}
                        </label>
                    </div>
                </form>
                <button className="send-btn text-secondary bg-warning border-0" onClick={handleSubmit}>
                    {t("Küldés")}
                </button>
                <p className="text-warning d-inline mx-2">{errorMessage}</p>
            </div>
        </div>
    );
}
