import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiURL } from "../config/config";
import axios from "axios";

//imports of bootstrap and icons
import { Row, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faUser, faBed, faArrowDown, faDoorClosed, faShip, faRulerHorizontal, faRulerVertical,faWind, faAngleDown, faArrowLeft, faInfo, faUsers } from "@fortawesome/free-solid-svg-icons";

//modals
import ReservationModal from "./ReservationModal";

// redux store
import { connect } from "react-redux";
import { getIsLoggedIn, toggleIsLoggedIn } from "../state/store";

import { Cookies } from "react-cookie";
import LoginModal from "./LoginModal";
import Carosuel from "./ImageCarosuel";
import RegisterModal from "./RegisterModal";

import { useTranslation } from "react-i18next";


function OfferDetails(props) {

    const { t } = useTranslation("common");


    //datas for yacht informations
    const [yachtData, setYachtData] = useState(false);
    const [priceData, setPriceData] = useState({});
    const [images, setImages] = useState([]);

    const cookies = new Cookies();

    const [choosedServsAndEqus, setChoosedServsAndEqus] = useState({
        equipment: [],
        services: [],
    });

    const history = useHistory();

    //show equippment lists
    const [showstandardEquipment, setShowstandardEquipment] = useState(false);
    const [showAdditionalEquipment, setshowAdditionalEquipment] = useState(false);
    const [showServices, setShowServices] = useState(false);

    //show modals
    const [ showReservationModal, setShowReservationModal ] = useState(false);
    const [ showLogin, setShowLogin ] = useState(false);
    const [ showRegister, setShowRegister ] = useState(false);

    //Detailed picture modal
    const [ showDetailedPicture, setShowDetailedPicture] = useState(false);
    const [ pictureUrl, setPictureUrl] = useState('');

    const toggleShowLogin = () => setShowLogin(!showLogin);

    const handleShowRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    };

    useEffect(() => {
        //scroll to top
        window.scrollTo(0, 0)

        //yacht informations from database by the yacht id
        axios.get(apiURL + "/detailedyacht/" + props.id).then((res) => {
                if(props.from && props.to) {
                    setYachtData({ ...res.data, from: props.from, to: props.to });
                }
                else {
                    setYachtData({ ...res.data });
                }
                setImages(res.data.yacht.pictures.map((x) => x.src))
            })
            .catch((err) => {
                console.log("Failed to load boat data");
            });

        //get yacht avalability
        if(props.from && props.to) {
            let url = apiURL + "reservation/freeYachts?periodFrom=" + props.from + "&periodTo=" + props.to + "&yacht=" + props.id;
            axios.get(url).then((res) => {
                if (res.data.freeYachts[0]) {
                    setPriceData(res.data.freeYachts[0].price);
                }
            });
        }
    }, []);

    const handleChooseServOrEqu = (value, type) => {
        if (choosedServsAndEqus[type].includes(value)) {
            setChoosedServsAndEqus({ ...choosedServsAndEqus, [type]: [...choosedServsAndEqus[type].filter((x) => x !== value)] });
        } else {
            setChoosedServsAndEqus({ ...choosedServsAndEqus, [type]: [...choosedServsAndEqus[type], value] });
        }
    };

    function setPicturePreview(url) {
        setPictureUrl(url);
        setShowDetailedPicture(true)
    }

    return (
        <div className="offer-details">
            <div className="content">
                {history.length > 1 ? <button className="back-btn border-0 bg-transparent text-warning" onClick={() => history.goBack()}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    {t("Vissza")}
                </button> : <></>}

                {/* show yacht informations */}
                {yachtData ? (
                    <Row>
                        <Col md={7}>
                            <Carosuel setPicturePreview={setPicturePreview} images={images} />
                        </Col>
                        <Col md={5}>
                            <div className="details-container text-primary">
                                <p className="year text-light">{`${yachtData.yachtModel ? yachtData.yachtModel.name : ""} (${yachtData.yacht.buildYear})`}</p>
                                <h4 className="yachtData-name">{yachtData.yacht.name}</h4>
                                {/* <p className="rating">{yachtData.rating}</p> */}
                                <div className="detail-box">
                                    <h5 className="title">{t("Kikötő")}</h5>
                                    <div className="location d-flex align-items-center">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        <p className="location">{yachtData.location.name.textEN}</p>
                                    </div>
                                </div>

                                <div className="detail-box">
                                    <h5 className="title">{t("Adatok")}</h5>
                                    <div className="information-content d-flex">
                                        <div className="people info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faUser} />
                                            <p>
                                                {yachtData.yacht.berthsCabin}
                                                <span> {t("fő")}</span>
                                            </p>
                                        </div>
                                        <div className="bed info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faBed} />
                                            <p>
                                                {yachtData.yacht.berthsCabin}
                                                <span> {t("ágy")}</span>
                                            </p>
                                        </div>
                                        <div className="cabin info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faDoorClosed} />
                                            <p>
                                                {yachtData.yacht.cabins}
                                                <span> {t("kabin")}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="information-content d-flex">
                                        <div className="yachtData info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faShip} />
                                            <p>{yachtData.yacht.name}</p>
                                        </div>
                                        <div className="length info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faRulerHorizontal} />
                                            <p>
                                                {yachtData.yachtModel.beam}
                                                <span> m</span>
                                            </p>
                                        </div>
                                        <div className="height info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faRulerVertical} />
                                            <p>
                                                {yachtData.yachtModel.loa}
                                                <span> m</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="information-content d-flex">
                                        <div className="height info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faArrowDown} />
                                            <p>
                                                {yachtData.yachtModel.draft}
                                                <span> m</span>
                                            </p>
                                        </div>
                                        {yachtData.yacht ? <div className="yachtData info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faUsers} />
                                            <p>{t(yachtData.yacht.charterType)}</p>
                                        </div> : <></>}
                                        {yachtData.sailType ? <div className="yachtData info d-flex align-items-center">
                                            <FontAwesomeIcon icon={faWind} />
                                            <p>{yachtData.sailType.name.textEN}</p>
                                        </div> : <></>}
                                    </div>
                                </div>
                                {Object.keys(priceData).length ? <div className="detail-box">
                                    <h5 className="title">{t("Kiválasztott időszak")}</h5>
                                    <div className="availability d-flex align-items-center">
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <p>{yachtData.from + " - " + yachtData.to}</p>
                                    </div>
                                </div> : null}
                                <div className="detail-box align-items-center justify-content-between">
                                    <h5 className="title link mb-0" onClick={() => setShowstandardEquipment(!showstandardEquipment)}>
                                        {t("Standard felszerelés")}<FontAwesomeIcon className="text-primary" role="button" icon={faAngleDown}  />
                                    </h5>
                                    {showstandardEquipment && (
                                        <ul className="equ-list">
                                            {yachtData.standardEqu.length === 0 && <p>Nincs standard felszerelés</p>}
                                            {yachtData.standardEqu &&
                                                yachtData.standardEqu.map((item) => {
                                                    return <li key={item.id} className="equ-item">{item.equname.textEN}</li>;
                                                })}
                                        </ul>
                                    )}
                                </div>
                                <div className="detail-box align-items-center justify-content-between">
                                    <h5 className="title link mb-0" onClick={() => setshowAdditionalEquipment(!showAdditionalEquipment)}>
                                        {t("Bérelhető felszerelés")}<FontAwesomeIcon className="text-primary" role="button" icon={faAngleDown}  />
                                    </h5>
                                    {showAdditionalEquipment && (
                                        <div className="equ-chooser-container">
                                            {yachtData.additionalEqu.length === 0 && <p>Nincs bérelhető felszerelés</p>}
                                            {yachtData.additionalEqu &&
                                                yachtData.additionalEqu.map((item) => {
                                                    return (
                                                        <div className="equ-chooser" key={item.id}>
                                                            <p>
                                                                {item.equname.textEN} ({Math.round(item.price)} {item.currency})
                                                            </p>
                                                            <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                                                                <input type="checkbox" name={item.id} id={item.id} value={item.id} onChange={() => handleChooseServOrEqu(item.id, "equipment")} hidden />
                                                                <label htmlFor={item.id} className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                                <div className="detail-box align-items-center justify-content-between">
                                    <h5 className="title link mb-0" onClick={() => setShowServices(!showServices)}>
                                        {t("Szolgáltatások")}
                                        <FontAwesomeIcon className="text-primary" role="button" icon={faAngleDown} />
                                    </h5>
                                    {showServices && (
                                        <div className="equ-chooser-container">
                                            {yachtData.services.length === 0 && <p>Nincs bérelhető felszerelés</p>}
                                            <p className="service-title">{t("Extra szolgáltatások")}:</p>
                                            {yachtData.services &&
                                                yachtData.services.filter((serv) => !serv.obligatory).map((serv) => {
                                                        if (serv.availableOnAgencyPortal) {
                                                            return (
                                                                <div className="equ-chooser" key={serv.id}>
                                                                    <p>
                                                                        {serv.name.textEN}{serv.description.textEN ? " (" + serv.description.textEN + ")" : null} ({Math.round(serv.price)} {serv.currency})
                                                                    </p>
                                                                    <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                                                                        <input type="checkbox" checked={choosedServsAndEqus.services.includes(serv.id)} name={serv.id} id={serv.id} value={serv.id} onChange={() => handleChooseServOrEqu(serv.id, "services")} hidden />
                                                                        <label htmlFor={serv.id} className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                            <p className="service-title">{t("Kötelező szolgáltatások")}:</p>
                                            {yachtData.services &&
                                                yachtData.services.filter((serv) => serv.obligatory).length > 0 &&
                                                yachtData.services.filter((serv) => serv.obligatory).map((serv) => {
                                                        if (serv.availableOnAgencyPortal) {
                                                            return (
                                                                <div key={serv.id} className="equ-chooser">
                                                                    <p>
                                                                        {serv.name.textEN} ({Math.round(serv.price)} {serv.currency})
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                        </div>
                                    )}
                                </div>
                                {Object.keys(priceData).length ? <div className="detail-box price-container">
                                    <h5 className="title mb-0">{t("Ár")}</h5>
                                    <p className="price text-warning">
                                        {Math.round(priceData.clientPrice)} {priceData.currency}
                                    </p>
                                    {(choosedServsAndEqus.equipment.length || choosedServsAndEqus.services.length) ? <p style={{fontSize:"12px"}}><FontAwesomeIcon icon={faInfo} />Néhány kiválasztott szolgáltatás a Bázison fizetendő, ezeket az ár nem tartalmazza !</p> : null}
                                </div> : null}

                                {(Object.keys(priceData).length) ? (
                                    <button className="book-btn text-secondary bg-primary border-0" onClick={() => { cookies.get("token") ? setShowReservationModal(true) : setShowLogin(true); }}>
                                        {t("Foglalás")}
                                    </button>
                                ) : null}
                                {/* reservationmodal displayed if userdata is ready */}
                                {showReservationModal && <ReservationModal showModal={showReservationModal} setShowModal={setShowReservationModal} periodFrom={yachtData.from} periodTo={yachtData.to} yachtID={yachtData.yacht.id} choosedServsAndEqus={choosedServsAndEqus} />}
                                <LoginModal showLogin={showLogin} toggleShowLogin={toggleShowLogin} handleShowRegister={handleShowRegister}/>
                                <RegisterModal handleShowRegister={handleShowRegister} handleCloseRegister={() => setShowRegister(false)} showRegister={showRegister} />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <div className="lds-dual-ring"></div>
                )}
            </div>
            <Modal show={showDetailedPicture} onHide={() => setShowDetailedPicture(false)} dialogClassName="detailed-boat-picture">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={pictureUrl} alt="boat"></img>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    login: getIsLoggedIn(state),
});

const mapDispatchToProps = { toggleIsLoggedIn };

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
