import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import queryString from "query-string";


export default function SearchResult(props) {
    const { t } = useTranslation("common");

    const location = useLocation();
    const queryData = queryString.parse(location.search);

    const handleOnClick = (boat) => {
        window.open(`/details?yachtId=${boat.yacht.id}&periodFrom=${boat.periodFrom}&periodTo=${boat.periodTo}`);
    };

    const setSorting = (e) => {
        let tempArr = e.target.value.split(",");
        props.setUsedFilters({
            ...props.usedFilters,
            orderby: tempArr[0],
            desc: tempArr[1],
        });
    };

    /**
     * display current filters by type
     * @param {*} key 
     * @param {*} value 
     * @returns 
     */
    function checkActiveFilter(key, value) {
        if (["orderby", "desc"].includes(key) || !value || !value.length) {
            return <></>
        }
        switch (key) {
            case "yachtCategories":
            case "equipments":
                const displayedResult = []
                const searchFilter = props.searchFilters[key]
                if (searchFilter && value.length) {
                    value.forEach((valItem) => {
                        if (searchFilter[valItem] && searchFilter[valItem].name) {
                            displayedResult.push(t(searchFilter[valItem].name))
                        }
                    })
                }
                return (
                    <span>
                        {t(key)}: {displayedResult.join(", ")}
                    </span>
                )
            case "sailType":
                const displayedValue =
                    props.searchFilters["sailTypes"] && props.searchFilters["sailTypes"][value] && props.searchFilters["sailTypes"][value].name ? props.searchFilters["sailTypes"][value].name : ""
                return displayedValue ? (
                    <span>
                        {t("Vitorla")}: {displayedValue}
                    </span>
                ) : (
                    <></>
                )
            case "cabins":
                const temp = value
                    .join(", ")
                    .split(", ")
                    .sort((a, b) => a - b)
                const smallest = temp[0]
                const largest = temp[temp.length - 1] > 6 ? "6+" : temp[temp.length - 1]
                return (
                    <span>
                        {t("Kabinok száma")}: {`${smallest && smallest !== largest && smallest !== "6" ? smallest + "-" : ""}${largest}`}
                    </span>
                )
            case "boatType":
            case "buildYearFrom":
                return (
                    <span>
                        {t(key)}: {t(value)}
                    </span>
                )
            case "lengthFrom":
                const from = value
                const to = props.usedFilters.lengthTo ? props.usedFilters.lengthTo : ""
                return from && to && !(from === "0" && to === "100") ?(
                    <span>
                        {t("Hossz")}: {`${from}-${to}m`}
                    </span>
                ) : <></>
            default:
                return <></>
        }
    }

    /**
     * display the query data in the used filters (locations, period, beds)
     * @returns 
     */
    function displayQueryData() {
        const displayedData = Object.entries(queryData).map(([key, value]) => {
            switch (key) {
                case "persons":
                    return (
                        <span>
                            {t("Ágyak")}: {value}
                        </span>
                    )
                case "countries":
                case "regions":
                case "locations":
                    console.log("props.decodedQuery", props.decodedQuery);
                    const country = props.decodedQuery["countries"] && props.decodedQuery["countries"].name ? props.decodedQuery["countries"].name.textEN : ""
                    const region = props.decodedQuery["regions"] && props.decodedQuery["regions"].name ? props.decodedQuery["regions"].name.textEN : ""
                    const location = props.decodedQuery["locations"] && props.decodedQuery["locations"].name ? props.decodedQuery["locations"].name.textEN : ""
                    return props.decodedQuery && props.decodedQuery[key] && props.decodedQuery[key].name ? (
                        <span>
                            {t("Honnan")}: {`${country} ${region ? "-> " + region : ""} ${location ? "-> " + location : ""}`}
                        </span>
                    ) : (
                        <></>
                    )
                case "periodFrom":
                case "periodTo":
                    return (
                        <span>
                            {t(key)}: {moment(value, "DD.MM.YYYY").format("YYYY. MM. DD.")}
                        </span>
                    )

                default:
                    return <></>
            }
        })
        return displayedData
    }

    function openSidebar() {
        let sidebar = document.querySelector(".filter-container");
        let body = document.querySelector("body");
        sidebar.classList.add("show");
        body.classList.add("blur");
    }

    useEffect(() => {
        let body = document.querySelector("body");
        let sidebar = document.querySelector(".filter-container");

        window.addEventListener("resize", function (event) {
            if (window.innerWidth > 991) {
                body.classList.remove("blur");
                sidebar.classList.remove("show");
            }
        });
    });

    return (
        <div className="search-results">
            <div className="top-container d-lg-flex justify-content-between text-center">
                <h2 className="main-title text-warning mb-4 mb-lg-0">{t("Találati lista")}</h2>
                <div className="px-3 d-flex align-items-center justify-content-between">
                    <div className="open-menu text-left" onClick={openSidebar}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <select name="order" id="order" defaultValue="2,0" className="border-0 text-primary" onChange={setSorting}>
                        <option value="2,0">{t("Ár szerint növekvő")}</option>
                        <option value="2,1">{t("Ár szerint csökkenő")}</option>
                        <option value="3,0">{t("Hossz szerint növekvő")}</option>
                        <option value="3,1">{t("Hossz szerint csökkenő")}</option>
                        <option value="4,0">{t("Kabinok szerint növekvő")}</option>
                        <option value="4,1">{t("Kabinok szerint csökkenő")}</option>
                        <option value="5,0">{t("Kor szerint növekvő")}</option>
                        <option value="5,1">{t("Kor szerint csökkenő")}</option>
                    </select>
                </div>
            </div>
            <Row className="active-filters-container">
                {displayQueryData()}
                {Object.entries(props.usedFilters).map(([key, value]) => {
                    return checkActiveFilter(key, value)
                })}
            </Row>
            <Row>
            {props.isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {props.yachts && props.yachts.length ? (
                            props.yachts.map((boat) => {
                                return !boat.yacht ? <></> : (
                                    <Col key={boat.yachtId} sm={6} lg={4}>
                                        <div className="ship-box h-100 d-flex flex-column">
                                            <div className="image-container">
                                                <img src={boat.yacht.mainPictureUrl || ""} alt="ship" onClick={() => handleOnClick(boat)} />
                                                {boat.price && boat.price.discounts && boat.price.discounts.length ? <p className="discount text-secondary bg-warning">-{boat.price.discounts[0].amount}%</p> : null}
                                            </div>
                                            <div className="box-content flex-grow-1 d-flex flex-column">
                                                <p className="model text-primary">{`${boat.yachtModel} (${boat.yacht.buildYear})`}</p>
                                                <h4 className="title yacht-name text-primary">{boat.yacht.name}</h4>
                                                {boat.locationFromName && <p className="location text-primary">{boat.locationFromName.name.textEN}</p>}
                                                {boat.sailType ? <p className="year text-primary">{`${t("Vitorla")}: ${t(boat.sailType.textEN)}`}</p> : <></>}
                                                <p className="year mb-1">{t(boat.yacht.charterType)}</p>
                                                <div className="price-container">
                                                    <div>
                                                        <p className="price">€{Math.round(boat.price.clientPrice)}</p>
                                                    </div>
                                                    <div className="price-list-price">
                                                        {boat.price.clientPrice !== boat.price.priceListPrice ? <p className="old-price text-light">
                                                        <span>€</span>
                                                        {Math.round(boat.price.priceListPrice)}
                                                        </p> : <></>}
                                                    </div>
                                                    
                                                </div>
                                                <div className="flex-grow-1 d-flex flex-column justify-content-end">
                                                    <div className="d-flex align-items-center align-items-lg-start align-items-xl-center  flex-lg-column flex-xl-row">
                                                        <button onClick={() => handleOnClick(boat)} className="view-btn border-0 bg-transparent text-warning d-block ml-auto ml-lg-0 ml-xl-auto">
                                                            {t("Megnézem")}
                                                            <FontAwesomeIcon className="d-none d-sm-inline-block" icon={faArrowRight} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        ) : (
                            <p style={{ margin: "auto", color: "grey" }}>{t("A megadott feltételekkel jelenleg nincs elérhető yacht.")}</p>
                        )}
                    </>
                )}
            </Row>
        </div>
    );
}
