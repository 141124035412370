import React, { useEffect, useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import MDEditor from "@uiw/react-md-editor"
import axios from "axios"
import { apiURL } from "../config/config"
import { Cookies } from "react-cookie"

function TermsOfUse() {
    const cookies = new Cookies()

    const [termsOfUse, setTermsOfUse] = useState("")

    useEffect(() => {
        fetchPrivacyStatement()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchPrivacyStatement() {
        try {
            const response = await axios.get(apiURL + "markdown/terms-of-use", {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            })
            setTermsOfUse(response.data.markDownText)
        } catch (error) {
            console.log("Failed to update the text")
        }
    }

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="terms-of-use">
                <div className="content text-primary">
                    <h2 className="main-title text-warning text-center text-lg-left">Felhasználási feltételek</h2>

                    <div className="content-block">
                        <MDEditor.Markdown source={termsOfUse} />
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

export default TermsOfUse
