import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import { apiURL } from "../config/config"
import { useTranslation } from "react-i18next"
import Loader from "../components/Loader"

export default function RelatedOffers(props) {
    const { t } = useTranslation("common")

    const [offeredYachts, setOfferedYachts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        const filtersForFreeYachtSearch = {
            periodFrom: props.from,
            periodTo: props.to,
            orderby: 2,
            desc: 0,
            resultsPage: 1,
            resultsPerPage: 4,
        }

        let filterQuery = "?"
        Object.entries(filtersForFreeYachtSearch).forEach(([key, value]) => {
            filterQuery += "&" + key + "=" + value
        })

        axios
            .get(apiURL + "yachtsearch" + filterQuery)
            .then((res) => {
                setOfferedYachts(res.data.freeYachtsInPeriod)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("Err", err)
                setIsLoading(false)
            })
    }, [])

    //TODO: Ezt át kell nézni
    const handleOnClick = (boat) => {
        window.open(`/details?yachtId=${boat.yacht.id}&periodFrom=${boat.periodFrom}&periodTo=${boat.periodTo}`);
    }

    return (
        <div className="related-offers">
            {isLoading ? (
                <div className="loader-container">
                    <Loader />
                </div>
            ) : (
                <div className="content">
                    <div className="top-container d-lg-flex justify-content-between text-center">
                        <h2 className="main-title text-warning">{t("Ezek is tetszhetnek Önnek")}</h2>
                    </div>
                    <Row className="justify-content-center justify-content-lg-between">
                        {offeredYachts &&
                            offeredYachts.map((yacht) => {
                                return yacht.yacht ? (
                                    <Col key={yacht.yachtId} lg={3} md={6} className="mb-4">
                                        <div className="ship-box h-100 d-flex flex-column">
                                            <div className="image-container">
                                                <img src={yacht.yacht.mainPictureUrl} alt="ship" />
                                                {yacht.price.discounts.length ? <p className="discount text-secondary bg-warning">-{yacht.price.discounts[0].amount}%</p> : null}
                                            </div>
                                            <div className="box-content flex-grow-1 d-flex flex-column">
                                                <p className="year text-light">{`${yacht.yachtModel} (${yacht.yacht.buildYear})`}</p>
                                                <h4 className="title text-primary">{yacht.yacht.name}</h4>
                                                <p className="year text-primary">({yacht.yacht.buildYear})</p>
                                                <p className="availability text-primary">
                                                    {yacht.periodFrom} - {yacht.periodTo}
                                                </p>
                                                <div className="bottom-content flex-grow-1 d-flex flex-column justify-content-end">
                                                    <div>
                                                        <p className="price text-secondary">€{Math.round(yacht.price.clientPrice)}</p>
                                                    </div>
                                                    <button onClick={() => handleOnClick(yacht)} className="view-btn border-0 bg-transparent text-warning d-block ml-auto">
                                                        {t("Megnézem")}
                                                        <FontAwesomeIcon className="d-none d-sm-inline-block" icon={faArrowRight} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ) : <></>
                            })}
                    </Row>
                </div>
            )}
        </div>
    )
}
