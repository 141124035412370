import React, { useState, useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import axios from "axios";
import { apiURL } from "../config/config";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { getIsLoggedIn, toggleIsLoggedIn } from "../state/store";
import logo from "../assets/images/yachtpick-logo-RGB_positive-cut.svg";

const registrationInitData = {
    surname: "",
    firstname: "",
    email: "",
    password: "",
    password_again: "",
    phone: "",
    country: "",
    city: "",
    address: "",
    zip: "",
};

let cookies = new Cookies();

function RegisterModal({ showRegister, handleCloseRegister, toggleIsLoggedIn }) {
    const [registrationData, setregistrationData] = useState({ ...registrationInitData });
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
            setMessage("");
        }, 5000);
    }, [errorMessage, message]);

    const handleFormChange = (e) => {
        setregistrationData({
            ...registrationData,
            [e.target.name]: e.target.value,
        });
    };

    //TODO Have to check if the two password is the same
    const handleRegistration = (e) => {
        e.preventDefault();
        if (registrationValidator()) {
            axios
                .post(apiURL + "register", registrationData)
                .then((response) => {
                    cookies.set("token", response.data.token, { path: "/" });
                    toggleIsLoggedIn();
                    setregistrationData({ ...registrationInitData });
                    setMessage("Sikeres regisztráció");
                    setTimeout(() => {
                        handleCloseRegister();
                    }, 3000);
                })
                .catch((error) => {
                    setErrorMessage(error.response.data);
                });
        } else {
        }
    };

    function registrationValidator() {
        let valid = true;
        Object.values(registrationData).forEach((value) => {
            if (!value) {
                setErrorMessage("Minden mező kitöltése kötelező!");
                valid = false;
            }
        });
        if (registrationData.password.length < 4) {
            setErrorMessage("A megadott jelszó túl rövid");
            valid = false;
        }
        if (registrationData.password !== registrationData.password_again) {
            setErrorMessage("A két jelszó mező nem egyezik");
            valid = false;
        }
        if (registrationData.phone.length < 7) {
            setErrorMessage("A megadott telefonszám nem megfelelő");
            valid = false;
        }
        if (!registrationData.email.includes("@")) {
            setErrorMessage("A megadott email cím formátuma nem megfelelő");
            valid = false;
        }
        return valid;
    }

    return (
        <Modal show={showRegister} onHide={handleCloseRegister} dialogClassName="register-popup text-primary">
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={logo} alt="logo" className="logo" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="title mb-0">Regisztráció</h3>
                <form>
                    <div className="form-group d-flex">
                        <label htmlFor="email">EMAIL</label>
                        <input type="email" id="email" placeholder="email" name="email" value={registrationData.email} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="surname">VEZETÉKNÉV</label>
                        <input type="text" id="surname" placeholder="Vezetéknév" name="surname" value={registrationData.surname} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="firstname">KERESZTNÉV</label>
                        <input type="text" id="firstname" placeholder="Keresztnév" name="firstname" value={registrationData.firstname} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="password">JELSZÓ</label>
                        <input type="password" id="password" placeholder="*******" name="password" value={registrationData.password} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="password-again">JELSZÓ</label>
                        <input type="password" id="password-again" placeholder="Jelszó újra" name="password_again" value={registrationData.password_again} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="country">ORSZÁG</label>
                        <input type="text" id="country" placeholder="ország" name="country" value={registrationData.country} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="city">VÁROS</label>
                        <input type="text" id="country" placeholder="city" name="city" value={registrationData.city} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="address">CÍM</label>
                        <input type="text" id="address" placeholder="Cím" name="address" value={registrationData.address} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="address">ZIP</label>
                        <input type="text" id="zip" placeholder="zip" name="zip" value={registrationData.zip} onChange={handleFormChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="phone">TELEFONSZÁM</label>
                        <input type="text" id="phone" placeholder="Telefonszám" name="phone" value={registrationData.phone} onChange={handleFormChange} />
                    </div>
                </form>
                <div className="register-box">
                    {/* <form className="checkbox-form">
                            <div className="checkbox-container d-flex align-items-center">
                                <input type="checkbox" id="terms" hidden/>
                                <label htmlFor="terms" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                <label htmlFor="terms" className="checkbox-label mb-0">Elfogadom a Yachtbooking Általános Szerződési Feltételeit.</label>
                            </div>
                            <div className="checkbox-container d-flex align-items-center">
                                <input type="checkbox" id="newsletter" hidden/>
                                <label htmlFor="newsletter" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                <label htmlFor="newsletter" className="checkbox-label mb-0">Szeretnék hírleveleket kapni.</label>
                            </div>
                        </form> */}
                    <Row>
                        <Col xs={12} md={6} className="error-message">
                            <p className="text-primary">{message}</p>
                            <p className="text-warning">{errorMessage}</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <button className="register-btn border-0 text-secondary bg-warning d-block ml-auto" onClick={handleRegistration}>
                                Regisztráció
                            </button>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    login: getIsLoggedIn(state),
});

const mapDispatchToProps = { toggleIsLoggedIn };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);
