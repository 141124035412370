import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { apiURL } from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next"


export default function UserReservations(props) {

    const { t } = useTranslation("common")

    const [ picture, setPicture ] = useState('');
    const [ feedback, setFeedback ] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        getBoatData();
    }, []);

    useEffect(() => {
        if(feedback !== '') {
            setTimeout(() => {
                setFeedback('')
            }, 3000)
        }
    }, [feedback])

    /* TODO: Warning: Warning: Can't perform a React state update on an unmounted component.
    This is a no-op, but it indicates a memory leak in your application.
    To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function*/
    const getBoatData = async () => {
        try {
            let data = await axios.get(apiURL + "/detailedyacht/" + props.reservationData.yachtId)
            setPicture(data.data.yacht.mainPictureUrl)
        }
        catch {
            setFeedback('Jelenleg nem tudjuk betölteni a hajóhoz tartozó adatokat.');
        }
    }
    return(
        <Row className="reservations mb-4">
            <Col lg={4} className="mb-3 mb-lg-0">
                <img alt="boat" src={picture}/>
            </Col>
            <Col lg={8}>
                <p><span>{t("Hajó neve")}:</span> {props.reservationData.yachtName}</p>
                <p><span>{t("Időszak")}:</span> {props.reservationData.periodFrom} - {props.reservationData.periodTo}</p>
                <p><span>{t("Ár")}:</span> {Math.round(props.reservationData.localizedFinalPrice)} {props.reservationData.currency}</p>
                <Link className="details text-warning text-md-left d-block" to={"/details?yachtId=" + props.reservationData.yachtId}>{t("Hajó részletei")} <FontAwesomeIcon  icon={faArrowRight}/></Link>
                <Link className="details text-warning text-md-left d-block" to={"/profile/reservation/" + props.reservationData.id}>{t("Rendelés részletei")} <FontAwesomeIcon icon={faArrowRight}/></Link>
            </Col>
            {feedback.length ? <p>{feedback}</p> : null}
        </Row>
    );
}