import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiURL } from "../config/config";
import { Modal, Col, Row } from "react-bootstrap";
import logo from "../assets/images/yachtpick-logo-RGB_positive-cut.svg";
import { Cookies } from "react-cookie";

import { useTranslation } from "react-i18next";


// the possible states of the reservation process (used in the reservationState)
const reservationFlow = {
    WAITING_INFO: 1,
    BEFORE_RESERVATION: 2,
    WAITING_RESERVATION: 3,
    RESERVATION_READY: 4,
    ERROR: 5
}

export default function ReservationModal({ showModal, setShowModal, periodFrom, periodTo, yachtID, guestUser, choosedServsAndEqus }) {
    const { t } = useTranslation("common");
    
    // errormessages
    const [errorMessage, setErrorMessage] = useState("");

    const cookies = new Cookies();
    const token = cookies.get("token");

    //state to store info request response from backend
    const [info, setInfo] = useState();

    // this state handles the states of the reservation process; the initial state is WAITING INFO because the component waits for the info data (in useEffect)
    const [reservationState, setreservationState] = useState(reservationFlow.WAITING_INFO)

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 5000);
    }, [errorMessage]);

    //Handle info on load
    useEffect(() => {
        handleInfo();
    }, []);

    // send create info reservation request to the backend
    async function handleInfo() {
        try {
            //data for the request
            const reservationData = {
                client: guestUser,
                periodFrom: periodFrom,
                periodTo: periodTo,
                yachtID: yachtID,
                ...choosedServsAndEqus,
            };

            let response = null;

            //request (if user is logged in => token is added to request / if not => reservationData contains client)
            if (token) {
                response = await axios.post(apiURL + "booking/info", reservationData, { headers: { Authorization: "Bearer " + token } });
            }

            if (response.data.nausysResponse) {
                // the info request was succesful, the state is BEFORE RESERVATION, the info response data is stored in info state
                setInfo(response.data);
                setreservationState(reservationFlow.BEFORE_RESERVATION)
            } else {
                // error during the info query, the state is error (the error message is displayed)
                setErrorMessage("Sikertelen lekérés: " + handleErrorMessage(response.data.status));
                setreservationState(reservationFlow.ERROR)
            }
        } catch (error) {
            // error during the info query, the state is error (the error message is displayed)
            setErrorMessage("Sikertelen lekérés: " + error);
            setreservationState(reservationFlow.ERROR)
        }
    }

    // handle option request == reservation
    const handleOption = async (e, clientIntention) => {
        setreservationState(reservationFlow.WAITING_RESERVATION)
        try {
            e.preventDefault();
            //data for the query
            const optionData = {
                client: guestUser,
                clientIntention,
                id: info.nausysResponse.id,
                uuid: info.nausysResponse.uuid,
            };

            let response = null;

            //request (if user is logged in => token is added to request / if not => optionData contains client)
            if (token) {
                response = await axios.post(apiURL + "booking/option", optionData, { headers: { Authorization: "Bearer " + token } });
            }

            if (!response.data.errorCode) {
                // succesful reservation, the status of the process is ready
                setreservationState(reservationFlow.RESERVATION_READY)
            } else {
                // error during the reservation, the state is error (the error message is displayed)
                setErrorMessage("Sikertelen lekérés: " + handleErrorMessage(response.data.status));
                setreservationState(reservationFlow.ERROR)
            }
        } catch (error) {
            // error during the reservation, the state is error (the error message is displayed)
            setErrorMessage("Sikertelen foglalás");
            setreservationState(reservationFlow.ERROR)
        }
    };

    const handleErrorMessage = (statusText) => {
        if (statusText === "OPERATION_NOT_ALLOWED") {
            return "a művelet végrehajtása nem engedélyezett vagy a hajó már foglalt a megadott időszakban";
        } else if ("NOT_ALLOWED_TO_BOOK_THIS_YACHT") {
            return "ennek a yachtnak a lefoglalása nem engedélyezett";
        } else if ("UNKNOWN_ERROR") {
            return "ismeretlen hiba";
        } else {
            return "hiányos vagy nem megfelelő adat";
        }
    };

    return (
        <Modal animation={false} show={showModal} onHide={setShowModal} dialogClassName="reservation-popup text-primary">
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={logo} alt="" className="logo" />
                </Modal.Title>
            </Modal.Header>


            {/* waiting phases, loader is displayed */}
            {(reservationState === reservationFlow.WAITING_INFO || reservationState === reservationFlow.WAITING_RESERVATION)  &&
                <Modal.Body>
                    <div style={{width:'20px'}} className="lds-dual-ring"></div>
                </Modal.Body>
            }

            {/* this part displayed if the info query was succesful and the state is before the reservation */}
            {reservationState === reservationFlow.BEFORE_RESERVATION && info && info.nausysResponse && info.client && (
                <Modal.Body>
                    <h3 className="title mb-3">{t("Foglalás részletei")}</h3>
                    <p>
                        <span className="font-weight-bold">{t("Ár")}:</span> {Math.round(info.nausysResponse.agencyClientFinalPrice)} {info.nausysResponse.currency}
                    </p>
                    <p>
                        <span className="font-weight-bold">Yacht:</span>Yacht: {info.nausysResponse.yachtName}
                    </p>
                    <p>
                        <span className="font-weight-bold">{t("Foglalás kezdete")}:</span> {info.nausysResponse.periodFrom}
                    </p>
                    <p>
                        <span className="font-weight-bold">{t("Foglalás vége")}:</span> {info.nausysResponse.periodTo}
                    </p>
                    <p>
                        <span className="font-weight-bold">{t("Foglaló")}: </span> {info.client.name} {info.client.surname}
                    </p>
                    <p>
                        <span className="font-weight-bold">Email: </span> {info.client.email}
                    </p>
                    <p>
                        <span className="font-weight-bold">{t("Telefon")}:</span> {info.client.phone}
                    </p>

                    <div className="register-box">
                        <Row>
                            {/* button for option query, userIntention is reservation */}
                            {info && info.nausysResponse && info.nausysResponse.status === "OK" && (
                                <Col xs={6}>
                                    <button className="register-btn border-0 text-secondary bg-warning d-block ml-auto" onClick={(e) => handleOption(e, "RESERVATION")}>
                                        {t("Foglalás")}
                                    </button>
                                    <p className="button-info">{t("Végleges foglalás fizetési kötelezettséggel")}.</p>
                                </Col>
                            )}
                            {/* button for option query, userIntention is just request */}
                            {info && info.nausysResponse && info.nausysResponse.status === "OK" && (
                                <Col xs={6}>
                                    <button className="register-btn border-0 text-secondary bg-warning d-block ml-auto" onClick={(e) => handleOption(e, "REQUEST")}>
                                        {t("Opciózás")}
                                    </button>
                                    <p className="button-info">{t("Ideiglenes foglalás véglegesítés nélkül")}.</p>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Modal.Body>
            )}

            {reservationState === reservationFlow.RESERVATION_READY &&
                <Modal.Body>
                    <h3 className="title mb-3">{t("Sikeres foglalás")}</h3>
                    <p>{t("A megadott email címre elküldtük a foglalás részleteit")}.</p>
                </Modal.Body>
            }

            {reservationState === reservationFlow.ERROR &&
                <Modal.Body>
                    <h3 className="title mb-3">{t("Sikertelen foglalás")}</h3>
                    <p>{errorMessage}</p>
                </Modal.Body>
            }
        </Modal>
    );
}
