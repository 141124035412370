import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { apiURL } from "../config/config"
import axios from "axios"
import logo from "../assets/images/yachtpick-logo-RGB_positive-cut.svg"
import { useTranslation } from "react-i18next"


export default function ForgotPassword(props) {
    const { t } = useTranslation("common")

    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("")
        }, 6000)
    }, [errorMessage])

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    async function handlePasswordResetRequest() {
        try {
            if (email.length) {
                await axios.get(apiURL + "reset?email=" + email)
                setErrorMessage(t("A jelszó megváltoztatásához szükséges linket kiküldtük a megadott email cimre."))
            } else {
                setErrorMessage(t("Kérjük töltse ki az e-mail mezőt!"))
            }
        } catch (error) {
            setErrorMessage(t("A jelszó kiküldése nem sikerült"))
        }
    }

    return (
        <Modal show={props.show} onHide={props.close} dialogClassName="forgotpassword-popup text-primary">
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={logo} alt="logo" className="logo" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="title mb-3">{t("Elfelejtett jelszó")}</h3>
                <form>
                    <div className="form-group d-flex">
                        <label htmlFor="email">EMAIL</label>
                        <input type="email" id="email" name="email" value={email.email} placeholder="jack123@email.com" onChange={handleChange} />
                    </div>
                </form>
                <p className="text-warning">{errorMessage}</p>
                <div className="login-box d-sm-flex justify-content-between">
                    <div className="login-btn-container">
                        <button className="login-btn border-0 text-secondary bg-warning" onClick={() => handlePasswordResetRequest()}>
                            {t("Küldés")}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
