import React, { useEffect, useRef, useState } from "react";

export default function Carosuel({ images, setPicturePreview }) {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState();
    const carouselItemsRef = useRef([]);

    useEffect(() => {
        if (images && images[0]) {
            carouselItemsRef.current = carouselItemsRef.current.slice(0, images.length);

            setSelectedImageIndex(0);
            setSelectedImage(images[0]);
        }
    }, [images]);

    const handleSelectedImageChange = (newIdx) => {
        if (images && images.length > 0) {
            setSelectedImage(images[newIdx]);
            setSelectedImageIndex(newIdx);
            if (carouselItemsRef.current[newIdx]) {
                carouselItemsRef.current[newIdx].scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
            }
        }
    };

    return (
        <div className="carousel-container">
            <div className="selected-image" style={{ backgroundImage: `url(${selectedImage})`, cursor: 'pointer' }} onClick={() => setPicturePreview(selectedImage)}/>
            <div className="carousel">
                <div className="carousel__images">
                {images &&
                    images.map((image, idx) => (
                    <div
                        onClick={() => handleSelectedImageChange(idx)}
                        style={{ backgroundImage: `url(${image})` }}
                        key={image}
                        className={`carousel__image ${selectedImageIndex === idx && "carousel__image-selected"}`}
                        ref={(el) => (carouselItemsRef.current[idx] = el)}
                    />
                    ))}
                </div>
            </div>
        </div>
    );
};
