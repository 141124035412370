import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_en from './i18n/translations/en/common.json';
import common_hu from './i18n/translations/hu/common.json';

// init translation with default fallback lang HU
i18next.init({
    interpolation: { escapeValue: false },
    fallbackLng: 'hu',
    lng: "hu",
    resources: {
        en: {
            common: common_en
        },
        hu: {
            common: common_hu
        }
    },
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

