import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { Row, Col } from "react-bootstrap"
import Loader from "./Loader"
import axios from "axios"
import { apiURL } from "../config/config"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"

export default function SearchFilters(props) {
    const history = useHistory()
    const location = useLocation()

    const { t } = useTranslation("common")

    const [equipmentSearch, setEquipmnentSearch] = useState({ value: "", selected: false })
    const [choosedLength, setchoosedLength] = useState([])

    const [countrySearch, setCountrySearch] = useState("")
    const [regionSearch, setRegionSearch] = useState("")
    const [locationSearch, setLocationSearch] = useState("")

    const [locationLoading, setLocationLoading] = useState(false)

    const [locationDatas, setLocationDatas] = useState({
        countries: [],
        regions: [],
        locations: [],
    })

    useEffect(() => {
        getLocationOptions()
    }, [location.search])

    /**
     * fetch location options from the database
     * get only the locations and regions of the current country
     */
    async function getLocationOptions() {
        try {
            // set the location query by the url
            let queryData = queryString.parse(location.search)
            const countryId = queryData.countries ? queryData.countries : ""
            const regionId = queryData.regions ? queryData.regions : ""
            const locationId = queryData.locations ? queryData.locations : ""
            const queryParams = {
                ...(countryId && { countryId }),
                ...(regionId && { regionId }),
                ...(locationId && { locationId }),
                ...(!countryId && !regionId && !locationId && { onlyCountries: true }),
            }
            const response = await axios.get(`${apiURL}catalouge/locations`, {
                params: queryParams,
            })
            setLocationDatas(response.data)
        } catch (error) {
            console.log("Failed to get location datas")
        }
    }

    /**
     * update the url search query by the new location data
     * @param {*} locationData 
     */
    function handleLocationUpdate(locationData) {
        setLocationLoading(true)
        let queryData = queryString.parse(location.search)
        delete queryData.countries
        delete queryData.regions
        delete queryData.locations
        queryData = { ...queryData, ...locationData }
        let searchParams =
            "?" +
            Object.entries(queryData)
                .map(([key, value]) => {
                    return key + "=" + value
                })
                .join("&")
        history.push({
            pathname: "/search",
            search: searchParams,
        })
        setLocationLoading(false)
    }

    function updateSearch(text) {
        setEquipmnentSearch({ value: text, selected: false })
    }

    function setArrayFilters(category, value) {
        let temp = [...props.usedFilters[category]]
        if (value.includes("+")) {
            let delimiter = Number(value.slice(0, -1))
            fillArray(category, delimiter, temp)
        } else if (temp.includes(value)) {
            props.setUsedFilters({
                ...props.usedFilters,
                [category]: [...props.usedFilters[category]].filter((x) => {
                    return x !== value
                }),
            })
        } else {
            props.setUsedFilters({
                ...props.usedFilters,
                [category]: [...props.usedFilters[category], value],
            })
        }
    }

    function setNormalFilters(category, value) {
        props.setUsedFilters({
            ...props.usedFilters,
            [category]: value,
        })
    }

    // function to fill up or filter arrayfilters with numbers from a delimiter value
    function fillArray(category, delimiter, currentValues) {
        if (currentValues.includes(String(delimiter))) {
            props.setUsedFilters({
                ...props.usedFilters,
                [category]: [...props.usedFilters[category]].filter((x) => {
                    return x < delimiter
                }),
            })
        } else {
            props.setUsedFilters({
                ...props.usedFilters,
                [category]: [...props.usedFilters[category], ...[...Array(30)].map((_, index) => String(index + delimiter))],
            })
        }
    }

    function setLength(e) {
        let inputValues = e.target.value.split(",")
        if (e.target.checked) {
            setchoosedLength([...choosedLength, inputValues[0], inputValues[1]])
        } else {
            setchoosedLength([...choosedLength.filter((x) => x !== inputValues[0] && x !== inputValues[1])])
        }
    }

    useEffect(() => {
        if (choosedLength.length) {
            let temp = choosedLength
            temp.sort(function (a, b) {
                return a - b
            })
            props.setUsedFilters({ ...props.usedFilters, lengthFrom: temp[0], lengthTo: temp[temp.length - 1] })
        } else {
            props.setUsedFilters({ ...props.usedFilters, lengthFrom: "0", lengthTo: "100" })
        }
    }, [choosedLength])

    function setupFilters() {
        return Object.entries(props.searchFilters).map(([searchFilterKey, filterObj]) => {
            if (searchFilterKey === "yachtCategories") {
                return (
                    <div className="filter-box" key={searchFilterKey}>
                        <h3 className="filter-title text-uppercase">{t(searchFilterKey)}</h3>
                        {Object.entries(filterObj)
                            .slice(0, 20)
                            .map(([key, value]) => {
                                return (
                                    <div key={key} className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                                        <input type="checkbox" name={value.name} id={key} value={key} onChange={(e) => setArrayFilters(searchFilterKey, e.target.value)} hidden />
                                        <label htmlFor={key} className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                        <label htmlFor={key} className="checkbox-label mb-0">
                                            {t(value.name)}
                                        </label>
                                    </div>
                                )
                            })}
                    </div>
                )
            } else if (searchFilterKey === "equipments") {
                return (
                    <Row key={searchFilterKey} className="filter-box d-flex flex-wrap align-items-center equipments">
                        <Row className="equ-filter-container">
                            <Col>
                                <h3 className="filter-title text-uppercase">{t(searchFilterKey)}</h3>
                                <input
                                    id="from"
                                    type="text"
                                    placeholder={`${t(searchFilterKey)}...`}
                                    className="border-0"
                                    autoComplete="off"
                                    value={equipmentSearch.value}
                                    onChange={(evt) => {
                                        updateSearch(evt.target.value)
                                    }}></input>
                            </Col>
                        </Row>

                        <div className="equ-selector">
                            {Object.entries(filterObj)
                                .slice(0, 50)
                                .map(([key, value]) => {
                                    if (equipmentSearch.value !== "" && value.name.toLowerCase().includes(equipmentSearch.value.toLocaleLowerCase()) && !equipmentSearch.selected) {
                                        return (
                                            <p
                                                name={key}
                                                value={key}
                                                key={key}
                                                onClick={(e) => {
                                                    setEquipmnentSearch({ value: "", selected: false })
                                                    setArrayFilters(searchFilterKey, key)
                                                }}
                                                className="equ-select">
                                                {value.name}
                                            </p>
                                        )
                                    }
                                })}
                        </div>
                        <div className="d-block equ-list">
                            {props.usedFilters.equipments.map((eq) => {
                                return (
                                    <p key={eq.id} className="equ-list-item">
                                        {" "}
                                        {props.searchFilters.equipments[eq].name} <FontAwesomeIcon className="equ-list-item-icon" icon={faTimes} onClick={() => setArrayFilters(searchFilterKey, eq)} />
                                    </p>
                                )
                            })}
                        </div>
                    </Row>
                )
            } else if (searchFilterKey === "buildYearFrom") {
                return (
                    <div key={searchFilterKey} className="filter-box d-flex align-items-start align-items-xl-center  flex-column flex-xl-row ">
                        <h3 className="filter-title text-uppercase mb-2 mb-xl-0">{t("Kor")}:</h3>
                        <select defaultValue={""} className="ml-0 ml-xl-2" name="buildYearFrom" id={searchFilterKey} onChange={(e) => setNormalFilters(searchFilterKey, e.target.value)}>
                            <option value={""}>{t("Mindegy")}</option>
                            <option value={new Date().getFullYear() - 10}>{t("Maximum 10 éves")}</option>
                            <option value={new Date().getFullYear() - 5}>{t("Maximum 5 éves")}</option>
                            <option value={new Date().getFullYear() - 1}>{t("Új")}</option>
                        </select>
                    </div>
                )
            } else if (searchFilterKey === "boatType") {
                return (
                    <div key={searchFilterKey} className="filter-box d-flex align-items-start align-items-xl-center flex-column flex-xl-row ">
                        <h3 className="filter-title text-uppercase mb-2 mb-xl-0">{t("Legénységgel")}?</h3>
                        <select className="ml-0 ml-xl-2" name={searchFilterKey} id={searchFilterKey} onChange={(e) => setNormalFilters(searchFilterKey, e.target.value)}>
                            <option value="">{t("Mindegy")}</option>
                            <option value="CREWED">{t("Igen")}</option>
                            <option value="BAREBOAT">{t("Nem")}</option>
                        </select>
                    </div>
                )
            } else if (searchFilterKey === "sailTypes") {
                return (
                    <div key={searchFilterKey} className="filter-box d-flex align-items-start align-items-xl-center flex-column flex-xl-row ">
                        <h3 className="filter-title text-uppercase mb-2 mb-xl-0">{t("Vitorla")}</h3>
                        <select className="ml-0 ml-xl-2" name="sailType" id={searchFilterKey} onChange={(e) => setNormalFilters("sailType", e.target.value)}>
                            <option value={0}>{t("Mindegy")}</option>
                            {Object.entries(filterObj).map(([key, value]) => {
                                return (
                                    <option key={key} value={key}>
                                        {value.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                )
            } else if (searchFilterKey === "length") {
                return (
                    <div key={searchFilterKey} className="filter-box ">
                        <h3 className="filter-title text-uppercase">{t("Hossz")}:</h3>
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input type="checkbox" name="length-0-100" id="length-0-100" value="0,100" onChange={(e) => setLength(e)} hidden />
                            <label htmlFor="length-0-100" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="length-0-100" className="checkbox-label mb-0">
                                {t("Mindegy")}
                            </label>
                        </div>
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input type="checkbox" name="length-0-10" id="length-0-10" value="1,10" onChange={(e) => setLength(e)} hidden />
                            <label htmlFor="length-0-10" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="length-0-10" className="checkbox-label mb-0">
                                1m - 10m
                            </label>
                        </div>
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input type="checkbox" name="length-11-15" id="length-11-15" value="11,15" onChange={(e) => setLength(e)} hidden />
                            <label htmlFor="length-11-15" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="length-11-15" className="checkbox-label mb-0">
                                11m - 15m
                            </label>
                        </div>
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input type="checkbox" name="length-16-100" id="length-16-100" value="16,101" onChange={(e) => setLength(e)} hidden />
                            <label htmlFor="length-16-100" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="length-16-100" className="checkbox-label mb-0">
                                16m+
                            </label>
                        </div>
                    </div>
                )
            }
        })
    }

    function closeSidebar() {
        let sidebarr = document.querySelector(".filter-container")
        let body = document.querySelector("body")
        sidebarr.classList.remove("show")
        body.classList.remove("blur")
    }

    /**
     * handle location remove
     * @param {*} removedLocation 
     * @param {*} type 
     */
    function removeLocation(removedLocation, type) {
        switch (type) {
            case "countries":
                handleLocationUpdate({})
                break
            case "regions":
                handleLocationUpdate({ countries: removedLocation.countryId })
                break
            case "locations":
                handleLocationUpdate({ regions: removedLocation.regionId })
                break

            default:
                break
        }
    }

    /**
     * display the inputs and datas of the location filter
     * @param {*} type 
     * @param {*} value 
     * @param {*} setValue 
     * @param {*} placeholder 
     * @returns 
     */
    function displayLocationFilter(type, value, setValue, placeholder) {
        if (!locationDatas[type] || !locationDatas[type].length) {
            return <></>
        }

        return props.decodedQuery && props.decodedQuery[type] && props.decodedQuery[type].name ? (
            <p className={`location-item ${type}`}>
                {props.decodedQuery[type].name.textEN}
                {type !== "countries" ? <FontAwesomeIcon className="location-remove-icon" icon={faTimes} onClick={() => removeLocation(props.decodedQuery[type], type)} /> : <></>}
            </p>
        ) : (
            <>
                <input
                    id="from"
                    type="text"
                    placeholder={`${t(placeholder)}...`}
                    className="border-0"
                    autoComplete="off"
                    value={value}
                    onChange={(evt) => {
                        setValue(evt.target.value)
                    }}></input>
                <div className="location-selector">
                    {locationDatas &&
                        locationDatas[type] &&
                        locationDatas[type].slice(0, 50).map((locationItem) => {
                            if (value !== "" && locationItem.name.textEN.toLowerCase().includes(value.toLocaleLowerCase())) {
                                return (
                                    <p
                                        name={locationItem.name.textEN}
                                        value={locationItem.id}
                                        key={locationItem.id}
                                        onClick={(e) => {
                                            handleLocationUpdate({ [type]: locationItem.id })
                                            setValue("")
                                        }}
                                        className="location-select">
                                        {locationItem.name.textEN}
                                    </p>
                                )
                            }
                        })}
                </div>
            </>
        )
    }

    return (
        <div className="filter-container text-primary">
            {Object.keys(props.searchFilters).length ? (
                <>
                    <span className="close-menu" onClick={closeSidebar}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>

                    <div className="filter-box">
                        {props.decodedQuery && props.decodedQuery.countries && props.decodedQuery.countries.name ? (
                            <>
                                <h3 className="filter-title text-uppercase">{t("Honnan")}</h3>
                                <div className="location-filters">
                                    {locationLoading ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            {displayLocationFilter("countries", countrySearch, setCountrySearch, "Country")}
                                            {displayLocationFilter("regions", regionSearch, setRegionSearch, "Region")}
                                            {displayLocationFilter("locations", locationSearch, setLocationSearch, "Location")}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <h3 className="filter-title text-uppercase">{t("Kabinok száma")}</h3>
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input
                                type="checkbox"
                                name="cabins-2"
                                id="cabins-2"
                                value="-2"
                                onChange={(e) => {
                                    setArrayFilters("cabins", "0, 1, 2")
                                }}
                                hidden
                            />
                            <label htmlFor="cabins-2" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="cabins-2" className="checkbox-label mb-0">
                                0-2
                            </label>
                        </div>
                        {[...Array(6)].map((e, i) => {
                            if (i < 3) {
                                return <></>
                            }
                            return (
                                <div key={i} className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                                    <input type="checkbox" name={"cabins" + i} id={"cabins" + i} value={i} onChange={(e) => setArrayFilters("cabins", e.target.value)} hidden />
                                    <label htmlFor={"cabins" + i} className="checkbox-square d-flex align-items-center justify-content-center"></label>
                                    <label htmlFor={"cabins" + i} className="checkbox-label mb-0">
                                        {i}
                                    </label>
                                </div>
                            )
                        })}
                        <div className="checkbox-container d-flex align-items-center" style={{ marginTop: "5px" }}>
                            <input type="checkbox" name="cabins-6+" id="cabins-6+" value="6+" onChange={(e) => setArrayFilters("cabins", e.target.value)} hidden />
                            <label htmlFor="cabins-6+" className="checkbox-square d-flex align-items-center justify-content-center"></label>
                            <label htmlFor="cabins-6+" className="checkbox-label mb-0">
                                6+
                            </label>
                        </div>
                    </div>
                    {setupFilters()}
                </>
            ) : (
                <Loader />
            )}
        </div>
    )
}
