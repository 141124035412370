import React, { useEffect, useState } from 'react';
import Searchbar from '../components/Searchbar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Row, Col } from 'react-bootstrap';
import SearchFilters from '../components/SearchFilters';
import SearchResult from '../components/SearchResult';
import RegistrationBanner from '../components/RegistrationBanner';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
    faStepBackward,
    faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import { apiURL } from '../config/config';

export default function Search(props) {

    const [isLoading, setisLoading] = useState(false);

    const [searchFilters, setSearchFilters] = useState({});
    const [yachts, setYachts] = useState([]);
    const [usedFilters, setUsedFilters] = useState({
        orderby: "2",
        desc: "0",
        yachtCategories: [],
        cabins: [],
        berths: [],
        equipments: [],
        yachtModels: [],
        services: [],
        sailType: 0,
        buildYearFrom: "",
        boatType: "",
        lengthFrom: "0",
        lengthTo: "100"
    })

    //pagination
    const [page, setPage] = useState({
        currentPage: 0,
        endIndex: 0,
        totalPages: 0,
        resultsPerPage: 6,
        startIdx: 0,
        totalCount: 0,
    });

    const [decodedQuery, setDecodedQuery] = useState({
        countries: {},
        regions: {},
        locations: {},
    })

    //Get filters on page load only
    useEffect(() => {
        axios.get(apiURL + 'catalouge/filters').then((res) => {
            setSearchFilters(res.data);
        }).catch((err) => {
            console.log('Err', err);
        });
    }, [])

    //This will be our main useeffect to run on every filter change.
    useEffect(() => {
        setisLoading(true)
        let pagination = '&resultsPerPage=' + page.resultsPerPage + '&resultsPage=' + page.currentPage;

        let filters = "";
        Object.entries(usedFilters).forEach(([key, value])=>{
            if (!value.length < 1) {
                if (typeof(value) === "object") {
                    filters += "&" + key + "[]=" + value;
                }else{
                    filters += "&" + key + "=" + value;
                }
            }
        })

        axios.get(apiURL + 'yachtsearch' + props.location.search + pagination + filters).then((res) => {
            
            setYachts(res.data.freeYachtsInPeriod);
            let paginationData = {
                currentPage: res.data.currentPage,
                endIndex: res.data.endIdx,
                totalPages: res.data.totalPages,
                resultsPerPage: res.data.resultsPerPage,
                startIdx: res.data.startIdx,
                totalCount: res.data.totalCount,
            };

            console.log("RESPONSE LOC", res.data.countries, res.data.regions, res.data.locations);

            setDecodedQuery({
                countries: res.data.countries || {},
                regions: res.data.regions || {},
                locations: res.data.locations || {}
            })

            //emiatt nem tudom ráhivni az effectet mert örök loopba kerül
            setPage(paginationData);
            setisLoading(false)
        }).catch((err) => {
            console.log('Err', err);
        });
    }, [usedFilters, props.location.search, page.currentPage]);

    return (
        <div>
            <div className='main-container search-results'>
                <Header />
                <Searchbar  />
            </div>
            <Row className='search-screen-body'>
                <Col lg={3} className='sidebar-filter'>
                    <SearchFilters 
                        setSearchFilters={setSearchFilters}
                        searchFilters={searchFilters}
                        usedFilters={usedFilters}
                        decodedQuery={decodedQuery}
                        setUsedFilters={setUsedFilters} />
                </Col>
                <Col lg={8} className='search-result-container'>
                    <SearchResult yachts={yachts} decodedQuery={decodedQuery} usedFilters={usedFilters} searchFilters={searchFilters} setUsedFilters={setUsedFilters} isLoading={isLoading} />
                    {yachts.length ? <Row className="pagination">
                        <div className='pages'>
                            <FontAwesomeIcon icon={faStepBackward} onClick={() => setPage((prev) => { return { ...prev, currentPage: 1 }})} disabled={page.currentPage === page.startIdx + 1} />
                            <FontAwesomeIcon icon={faChevronLeft} onClick={() => setPage({ ...page, currentPage: page.currentPage - 1 > 0 ? page.currentPage - 1 : page.currentPage }) } disabled={props.page <= 0} />
                            <span> {page.currentPage} of {page.totalPages} </span>
                            <FontAwesomeIcon icon={faChevronRight} onClick={() => setPage({ ...page, currentPage: page.currentPage + 1 < page.totalPages ? page.currentPage + 1 : page.totalPages }) } disabled={props.page >= props.maxPage} />
                            <FontAwesomeIcon icon={faStepForward} onClick={() => setPage((prev) => { return { ...prev, currentPage: page.totalPages }}) } disabled={page.currentPage === page.endIndex - 1 } />
                        </div>
                    </Row> : null}
                </Col>
            </Row>
            <RegistrationBanner />
            <Footer />
        </div>
    );
}
