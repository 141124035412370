import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faUsers, faPen } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logo.svg"

export default function AdminSidebar(props) {
    const [page, setPage] = useState("booked")

    return (
        <Col className="adminsidebar w-100 h-100 ">
            <Row className="text-white text-uppercase font-weight-bold w-100 m-auto">
                <img className="logo" src={logo} alt="logo" />
            </Row>
            <Row>
                <Col md={12}>
                    <Row className={`sidebar-link text-light  pl-0 ${page === "booked" ? "active" : ""}`}>
                        <Col xs={2} md={2} className="pr-0 d-flex justify-content-end align-items-center">
                            <FontAwesomeIcon icon={faCalendarAlt} className="before-icon" />
                        </Col>
                        <Col xs={6} md={10}>
                            <Link onClick={() => setPage("booked")} className="sidebar-linktext" to="/admin/profile/booked">
                                Foglalások
                            </Link>
                        </Col>
                    </Row>
                    <Row className={`sidebar-link text-light  pl-0 ${page === "reservations" ? "active" : ""}`}>
                        <Col xs={2} md={2} className="pr-0 d-flex justify-content-end align-items-center">
                            <FontAwesomeIcon icon={faCalendarAlt} className="before-icon" />
                        </Col>
                        <Col xs={6} md={10}>
                            <Link onClick={() => setPage("reservations")} className="sidebar-linktext" to="/admin/profile/reservations">
                                Foglalási kérelmek
                            </Link>
                        </Col>
                    </Row>
                    <Row className={`sidebar-link text-light  pl-0 ${page === "users" ? "active" : ""}`}>
                        <Col xs={2} md={2} className="pr-0 d-flex justify-content-end align-items-center">
                            <FontAwesomeIcon icon={faUsers} className="before-icon" />
                        </Col>
                        <Col xs={6} md={10}>
                            <Link onClick={() => setPage("users")} className="sidebar-linktext" to="/admin/profile/users">
                                Felhasználók
                            </Link>
                        </Col>
                    </Row>
                    <Row className={`sidebar-link text-light  pl-0 ${page === "editable-texts" ? "active" : ""}`}>
                        <Col xs={2} md={2} className="pr-0 d-flex justify-content-end align-items-center">
                            <FontAwesomeIcon icon={faPen} className="before-icon" />
                        </Col>
                        <Col xs={6} md={10}>
                            <Link onClick={() => setPage("editor")} className="sidebar-linktext" to="/admin/profile/privacy-statemant">
                                Szerkeszthető szövegek
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}
