import React, { useState, useEffect } from 'react';
import Header from "../components/Header";
import OfferDetails from "../components/OfferDetails";
import RelatedOffers from "../components/RelatedOffers";
import SocialBanner from "../components/SocialBanner";
import Footer from "../components/Footer";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';


export default function Details(props) {
    const [detailsData, setDetailsData] = useState({
        yachtId: "",
        periodFrom: "",
        periodTo: ""
    })
    const location = useLocation();

    const [query, setQuery] = useState(queryString.parse(location.search))


    useEffect(() => {
        // get the data from the url
        setDetailsData({
            yachtId: query.yachtId,
            periodFrom: query.periodFrom,
            periodTo: query.periodTo
        })

    }, [query, location])

    return(
        <div >
            <div className="main-container short">
                <Header/>
            </div>
            {detailsData.yachtId &&<OfferDetails id={detailsData.yachtId} from={detailsData.periodFrom} to={detailsData.periodTo} />}
            <SocialBanner />
            {detailsData.periodFrom && detailsData.periodTo &&<RelatedOffers from={detailsData.periodFrom} to={detailsData.periodTo}/>}
            <Footer />
        </div>
    );
}