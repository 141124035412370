import React, { useState } from 'react'
import { faAngleDoubleLeft, faAngleDoubleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';


// Define a default UI for filtering
function GlobalFilter({
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = useState(globalFilter);
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
    
        return (
            <span>
                Search:{' '}
                <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Keresés`}
                style={{
                    fontSize: '1rem',
                    border: '0',
                }}
                />
            </span>)
}

export default function Table({ columns, data, handleShow }) {

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      setGlobalFilter,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, sortBy : [ {id:"createdDate", desc:true} ] },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    )
  
    return (
        <div style={{width:'100%'}}>
            <Row className="mb-4">
                <Col className="p-0">
                    <GlobalFilter
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </Col>
            </Row>
            <Row className="table-container">
                <table {...getTableProps()} className="admin-table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span> {column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp} /> : ''} </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => handleShow ? handleShow(row.original) : null}>
                                    {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                    })}
                                </tr>
                            )}
                        )}
                    </tbody>
                </table>
            </Row>
            <Row className="pagination">
                <div>
                    <button className="pagination-button double" onClick={() => gotoPage(0)} disabled={!canPreviousPage}><FontAwesomeIcon icon={faAngleDoubleLeft}/></button>
                    <button className="pagination-button single" onClick={() => previousPage()} disabled={!canPreviousPage}><FontAwesomeIcon icon={faChevronLeft}/></button>
                    <div className="info">
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <select value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                            </option>
                        ))}
                        </select>
                    </div>
                    <button className="pagination-button single" onClick={() => nextPage()} disabled={!canNextPage}><FontAwesomeIcon icon={faChevronRight}/></button>
                    <button className="pagination-button double" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><FontAwesomeIcon icon={faAngleDoubleRight}/></button>
                </div>
            </Row>
        </div>)
}