import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { apiURL } from "../../config/config";
import { connect } from "react-redux";
import { getIsLoggedInAdmin, toggleIsLoggedInAdmin } from "../../state/store";
import { Cookies } from "react-cookie";
import logo from "../../assets/images/logo.svg";

function AdminLogin(props) {
    const history = useHistory();
    const cookies = new Cookies();

    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });

    const [error, setError] = useState("");

    useEffect(() => {
        if (cookies.get("admintoken")) {
            history.push("/admin/profile");
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError("");
        }, 5000);
    }, [error]);

    const handleChange = (e) => {
        const newCredentials = { ...credentials, [e.target.name]: e.target.value };
        setCredentials(newCredentials);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (credentials.username === "" || credentials.password === "") {
            setError("A felhasználónév és a jelszó mezők kitöltése kötelező");
            setTimeout(() => {
                setError("");
            }, 3000);
        } else {
            try {
                const response = await axios.post(apiURL + "loginadmin", credentials);
                cookies.set("admintoken", response.data.token, { path: "/admin", maxAge: 36000 });
                cookies.set("admin", response.data.admin, { path: "/admin", maxAge: 36000 });
                props.toggleIsLoggedInAdmin();
                history.push("/admin/profile");
                setCredentials({
                    username: "",
                    password: "",
                });
            } catch (error) {
                if (error.response) {
                    setError(error.response.data);
                }
            }
        }
    };

    return (
        <div className="admin-login">
            <div className="admin-header d-flex align-items-center">
                <Container className="p-0 text-center text-md-left">
                    <a href="/">
                        <img className="logo" src={logo} alt="logo" />
                    </a>
                </Container>
            </div>
            <Container className="login-container">
                <Row className="title-row">
                    <Col className="">
                        <h4>Bejelentkezés</h4>
                    </Col>
                </Row>
                <Row className="form-row">
                    <Col className="">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="username">Felhasználónév</label>
                                <input type="text" className="form-control" name="username" id="username" onChange={handleChange} value={credentials.username} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Jelszó</label>
                                <input type="password" className="form-control" name="password" id="password" onChange={handleChange} value={credentials.password} />
                            </div>
                            <button type="submit" className="btn btn-primary w-100 mt-2">
                                Bejelentkezés
                            </button>
                            <p className="text-warning">{error}</p>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    login: getIsLoggedInAdmin(state),
});

const mapDispatchToProps = { toggleIsLoggedInAdmin };

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
