import React from "react";
import { Col, Row } from "react-bootstrap";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNavbar from "../../components/admin/AdminNavbar";
import Reservations from "../../components/admin/Reservations";
import UserList from "../../components/admin/UserList";
import BookedReservations from "../../components/admin/BookedReservations";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import TextEditors from "../../components/admin/TextEditors";

export default function AdminInfos(props) {
    return (
        <div>
            <Row md={12} className="w-100 m-auto">
                <Router>
                    <Col md={3} lg={2} className="p-0">
                        <AdminSidebar />
                    </Col>
                    <Col md={9} lg={10} className="infocol">
                        <AdminNavbar />
                        <Redirect from="/admin/profile" to="/admin/profile/booked" />
                        <Route path={`/admin/profile/booked`} component={BookedReservations} />
                        <Route path={`/admin/profile/reservations`} component={Reservations} />
                        <Route path={`/admin/profile/users`} component={UserList} />
                        <Route path={`/admin/profile/privacy-statemant`} component={TextEditors} />
                    </Col>
                </Router>
            </Row>
        </div>
    );
}
