import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { apiURL } from "../config/config";
import axios from "axios";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { getIsLoggedIn, toggleIsLoggedIn } from "../state/store";
import logo from "../assets/images/yachtpick-logo-RGB_positive-cut.svg";
import { useTranslation } from "react-i18next";


function LoginModal(props) {
    const { t } = useTranslation("common");

    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [rememberMe, setRememberMe] = useState(false)

    const cookies = new Cookies();

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 6000);
    }, [errorMessage]);

    const handleChange = (e) => {
        const newCredentials = {
            ...credentials,
            [e.target.name]: e.target.value,
        };
        setCredentials(newCredentials);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (credentials.email === "" || credentials.password === "") {
            setErrorMessage("Hiányzó email vagy jelszó!");
        } else {
            try {
                const response = await axios.post(apiURL + "login", credentials);
                props.toggleIsLoggedIn();
                if (rememberMe) {
                    cookies.set("token", response.data.token, { path: "/", maxAge: 36000 });
                } else {
                    cookies.set("token", response.data.token, { path: "/", maxAge: 3600 });
                }
                
                setCredentials({ email: "", password: "" });
                props.toggleShowLogin();
                if(props.closeNavbarOnLogin){
                    props.closeNavbarOnLogin()
                }
            } catch (error) {
                setErrorMessage(error.response.data);
            }
        }
    };

    return (
        <Modal show={props.showLogin} onHide={props.toggleShowLogin} dialogClassName="login-popup text-primary">
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={logo} alt="logo" className="logo" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="title mb-0">{t("Belépés")}</h3>
                {props.handleShowRegister && (
                    <div className="registration-container d-sm-flex align-items-center">
                        <p className="registration">{t("Nincs még fiókja?")}</p>
                        <button className="registration-link text-warning border-0 bg-transparent" onClick={props.handleShowRegister}>
                            {t("Regisztráljon!")}
                        </button>
                    </div>
                )}
                <form>
                    <div className="form-group d-flex">
                        <label htmlFor="email">EMAIL</label>
                        <input type="text" id="email" name="email" value={credentials.email} placeholder="jack123@email.com" onChange={handleChange} />
                    </div>
                    <div className="form-group d-flex">
                        <label htmlFor="password">{t("JELSZÓ")}</label>
                        <input type="password" id="password" name="password" value={credentials.password} placeholder="*******" onChange={handleChange} />
                    </div>
                </form>
                <p className="text-warning">{errorMessage}</p>
                <div className="login-box d-sm-flex justify-content-between">
                    <div className="checkbox-container d-flex align-items-center pb-4 pb-sm-0">
                        <input type="checkbox" id="checkbox" hidden checked={rememberMe} />
                        <label htmlFor="checkbox" className="checkbox-square d-flex align-items-center justify-content-center" onClick={() => setRememberMe(!rememberMe)}></label>
                        <label htmlFor="checkbox" className="checkbox-label mb-0">
                            {t("Jegyezzen meg")}
                        </label>
                    </div>
                    <div className="login-btn-container">
                        <button className="login-btn border-0 text-secondary bg-warning" onClick={handleSubmit}>
                            {t("Bejelentkezés")}
                        </button>
                    </div>
                </div>
                <button className="registration-link text-warning border-0 bg-transparent" onClick={() => { props.setshowForgotPassword(true); props.toggleShowLogin()}}>
                    {t("Elfelejtette a jelszavát?")}
                </button>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    login: getIsLoggedIn(state),
});

const mapDispatchToProps = { toggleIsLoggedIn };

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
