import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Searchbar from '../components/Searchbar';
import LastMinuteBanner from '../components/LastMinuteBanner';
import SpecialBanner from '../components/SpecialBanner';
import HowitWorkBanner from '../components/HowitWorkBanner';
import Newsletter from '../components/Newsletter';

export default function Homepage(props) {
    return (
        <div>
            <div className='main-container'>
                <Header />
                <Searchbar />
            </div>
            <LastMinuteBanner />
            <SpecialBanner />
            <HowitWorkBanner />
            {/* az elérhető hajóókat és a kiemelt hireket hagyjuk első körben */}
            <Newsletter />
            <Footer />
        </div>
    );
}
