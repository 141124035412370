import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from "react-i18next";

export default function Footer(props) {
    const { t } = useTranslation("common");

    return(
        <div className="social-container">
            <div className="content">
                <h2 className="main-title text-secondary">{t("Legyen részese a közösségnek!")}</h2>
                <h3 className="subtitle text-primary">{t("Kövessen minket a social media oldalakon!")}</h3>
                <div className="social-icons d-flex">
                    <a href="#" className="icon"><FontAwesomeIcon icon={faTwitter}/></a>
                    <a href="#" className="icon"><FontAwesomeIcon icon={faFacebookF}/></a>
                    <a href="#" className="icon"><FontAwesomeIcon icon={faLinkedinIn}/></a>
                    <a href="#" className="icon"><FontAwesomeIcon icon={faInstagram}/></a>
                </div>
            </div>
        </div>



    );
}