import { createStore as reduxCreateStore } from "redux"
import update from 'immutability-helper';
import { Cookies } from "react-cookie"

let cookies = new Cookies();


const INITIAL_STATE = {
    isLoggedIn: cookies.get("token") ? true : false,
    isLoggedInAdmin: cookies.get("admintoken") ? true : false,
    admin: cookies.get("admin") ? cookies.get("admin") : null,
}


const uiReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case TOGGLE_ISLOGGEDIN:
            return update(state, {
                isLoggedIn: {$set: !state.isLoggedIn}
            })
        case TOGGLE_ISLOGGEDIN_ADMIN:
            return update(state, {
                isLoggedInAdmin: {$set: !state.isLoggedInAdmin}
            })
        
        case RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

const RESET = 'RESET';
const TOGGLE_ISLOGGEDIN = 'TOGGLE_ISLOGGEDIN';
const TOGGLE_ISLOGGEDIN_ADMIN = 'TOGGLE_ISLOGGEDIN_ADMIN';

export const resetUI = () => ({
    type:RESET,
    payload: { }
});

export const toggleIsLoggedIn = (data) =>({
    type: TOGGLE_ISLOGGEDIN,
    payload: {}
});

export const toggleIsLoggedInAdmin = (data) =>({
    type: TOGGLE_ISLOGGEDIN_ADMIN,
    payload: {}
});

export const getIsLoggedIn = (state) => state.isLoggedIn;
export const getIsLoggedInAdmin = (state) => state.isLoggedInAdmin;
export const getAdmin = (state) => state.admin;


const createStore = () => reduxCreateStore(uiReducer, INITIAL_STATE)
export default createStore