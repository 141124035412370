import axios from "axios"
import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { apiURL } from "../config/config"
import { useTranslation } from "react-i18next"
import Loader from "./Loader"

export default function SpecialBanner() {
    const { t } = useTranslation("common")

    const [offer, setOffer] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    // style for the banner background
    const bgImageStyle = {
        position: "absolute",
        right: 0,
        top: 0,
        width: "65%",
        backgroundRepeat: "no-repeat",
        height: "100%",
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(to right, #dab782 10%, rgba(0, 0, 0, 0)),url(${offer && offer.yacht && offer.yacht.mainPictureUrl})`,
    }

    useEffect(() => {
        setIsLoading(true)
        try {
            axios.get(apiURL + "yachts/specialoffer").then((response) => {
                setOffer(response.data)
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }, [])

    const handleOnClick = () => {
        window.open(`/details?yachtId=${offer.yacht.id}&periodFrom=${offer.periodFrom}&periodTo=${offer.periodTo}`);
    }

    return (
        <div className="special-banner" id="special-banner">
            {isLoading ? (
                <div className="loader-container">
                    <Loader />
                </div>
            ) : (
                <>
                    {offer && offer.yacht && offer.yacht.mainPictureUrl ? <div className="yacht-bg" style={bgImageStyle}></div> : <></>}
                    <Row className="content text-primary">
                        <Col xs={12} sm={6}>
                            <h2 className="main-title text-secondary">{t("A YachtPick speciális ajánlata")}</h2>

                            {offer && offer.yacht && (
                                <>
                                    {offer.yachtModel ? <p className="year">{`${offer.yachtModel} (${offer.yacht.buildYear})`}</p> : <></>}
                                    <h4 className="ship-name">{offer.yacht.name}</h4>
                                    <p className="date">
                                        {offer.periodFrom}-{offer.periodTo}
                                    </p>
                                    <p className="price text-secondary">€{Math.round(offer.price.clientPrice)}</p>
                                    <button className="view-btn text-secondary bg-primary border-0" onClick={handleOnClick}>
                                        {t("Megnézem")}
                                    </button>
                                </>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}
