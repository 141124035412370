import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom"
import { getIsLoggedIn, getIsLoggedInAdmin } from "../state/store";

function PrivateRoute ({ component: Component, loginAdmin, loginUser, ...rest}) {

    const isAuthenticated = () => {
        if (rest.path === "/profile" || rest.path === "/profile/reservation/:id") {
            if(loginUser) {
                return true;
            }
            else {
                return false;
            }
        } else if(rest.path.includes('admin')) {
            if(loginAdmin) {
                return true;
            }
            else {
                return false;
            }
        }
    };

    return (
        <Route {...rest} render={(props) => isAuthenticated() ? <Component {...props} /> : <Redirect to="/" /> } />
    )
}

const mapStateToProps = (state) => ({
    loginAdmin:getIsLoggedInAdmin(state),
    loginUser:getIsLoggedIn(state)
});

export default connect(mapStateToProps, null)(PrivateRoute);