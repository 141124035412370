import axios from "axios"
import React, { useState } from "react"
import { Modal, Row, Form, Button } from "react-bootstrap"
import { apiURL } from "../../config/config"
import { Cookies } from "react-cookie"

const paymentDataInit = {
    paymentNumber: "",
    paymentDate: "",
}

export default function Payments(props) {
    const [paymentData, setPaymentData] = useState(paymentDataInit);
    const [feedback, setFeedback] = useState('');

    const cookies = new Cookies();


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(apiURL + "booking/setPayment", { 
                    id: props.id, 
                    ...paymentData
                },
                { 
                    headers: { Authorization: "Bearer " + cookies.get("admintoken")
                }
            })
            setFeedback('Sikeres megerősités');
            props.update();
            props.setDetails({...props.details, paymentStatus: "PAYED"})
            setTimeout(() => {
                props.toggleShowModal()
            }, 3000)
        }catch(err) {
            setFeedback('Fizetés megerősitése sikertelen.');
            props.toggleShowModal()

        }
    }

    return (
        <Modal animation={false} show={props.showModal} onHide={props.toggleShowModal} dialogClassName="payment-popup text-primary">
            <Modal.Header closeButton>
                <Modal.Title>{/* <img src={logo} alt="logo" className="logo" /> */}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h3 className="title">Fizetési adatok</h3>
                </Row>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="paymentNumber">
                            <Form.Label>Fizetési szám: </Form.Label>
                            <Form.Control type="text" name="paymentNumber" placeholder="fizetési szám" required onChange={(e) => setPaymentData({ ...paymentData, [e.target.name]: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="paymentDate">
                            <Form.Label>Fizetés dátuma: </Form.Label>
                            <Form.Control type="date" name="paymentDate" placeholder="fizetési szám" required onChange={(e) => setPaymentData({ ...paymentData, [e.target.name]: e.target.value })} />
                        </Form.Group>
                        {feedback.length ? <p>{feedback}</p> : null}
                        <Button className="button" variant="primary" type="submit">
                            Mentés
                        </Button>
                    </Form>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
