import React from "react"
import { Row, Col } from "react-bootstrap"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { useTranslation } from "react-i18next"


export default function HowItWorks() {
    const { t } = useTranslation("common")

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="howitworks-screen">
                <div className="content text-primary">
                    <h2 className="main-title text-warning text-center text-lg-left">{t("Hogyan működik a bérlés?")}</h2>
                    <Row>
                        <Col sm={12} className="content-item">
                            <h4 className="title text-center text-lg-left">
                                <span className="step-number">1</span> {t("Ajánlatküldés")}
                            </h4>
                            <p className="text-center text-lg-left">
                                {t("Az Önnek tetsző hajók ajánlatait egy gombnyomással elküldheti e-mailben. Ilyenkor rendszerünk egy PDF alapú dokumentumot generál, melyben szerepelnek a hajó adatai, a bérlési időszak és a fizetés részletei.")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="content-item">
                            <h4 className="title text-center text-lg-left">
                                <span className="step-number">2</span> {t("Opció")}
                            </h4>
                            <p className="text-center text-lg-left">
                                {t("Ha opciót indít egy hajóra, azzal biztosítja, hogy egy rövid ideig mások számára nem lesz foglalható. Ez idő alatt egyeztethet társaival és jelezheti felénk konkrét bérlési szándékát.Az opció ingyenes és nem jár bérlési kötelezettséggel.")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="content-item">
                            <h4 className="title text-center text-lg-left">
                                <span className="step-number">3</span> {t("Foglalás")}
                            </h4>
                            <p className="text-center text-lg-left">
                                {t("Amennyiben sikerült kiválasztani a megfelelő hajót, a végleges foglalást mi indítjuk el, a foglalási díj első részletének kifizetését követően.")}
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}
