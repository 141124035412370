import React, { useEffect, useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { useTranslation } from "react-i18next"
import MDEditor from "@uiw/react-md-editor"
import { Cookies } from "react-cookie"
import { apiURL } from "../config/config"
import axios from "axios"

export default function Contacts() {
    const { t } = useTranslation("common")

    const cookies = new Cookies()

    const [contacts, setContacts] = useState("")

    useEffect(() => {
        fetchContacts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchContacts() {
        try {
            const response = await axios.get(apiURL + "markdown/contacts", {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            })
            console.log("response", response.data)
            setContacts(response.data.markDownText)
        } catch (error) {
            console.log("Failed to update the text")
        }
    }

    return (
        <div>
            <div className="main-container search-results">
                <Header />
            </div>
            <div className="contacts">
                <div className="content text-primary">
                    <h2 className="main-title text-warning text-center text-lg-left">{t("Kapcsolat")}</h2>
                    <div className="content-block">
                        <MDEditor.Markdown source={contacts} />
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}
