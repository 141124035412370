import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { apiURL } from "../../config/config";
import { connect } from "react-redux";
import { getAdmin } from "../../state/store";
import moment from "moment";
import { Cookies } from "react-cookie";
import Table from "./adminTable";

export default function UserList(props) {
    const cookies = new Cookies();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios
            .get(apiURL + "/users", {
                headers: {
                    Authorization: "Bearer " + cookies.get("admintoken"),
                },
            })
            .then((response) => {
                setUsers(response.data);
            });
    }, []);

    //Setup table
    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Név",
                accessor: (originalRow, rowIndex) => {
                    return originalRow.surname + " " + originalRow.firstname;
                },
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Telefon",
                accessor: "phone",
            },
            {
                Header: "Cím",
                accessor: (originalRow, rowIndex) => {
                    return `${originalRow.country}, ${originalRow.zip}, ${originalRow.city} ${originalRow.address}`;
                },
            },
            {
                Header: "Regisztráció",
                accessor: (originalRow, rowIndex) => {
                    let date = moment(originalRow.createdAt, "YYYY-MM-DD HH:mm");
                    return moment(date).format("YYYY-MM-DD");
                },
            },
        ],
        []
    );

    const data = [...users];

    return (
        <div className="users text-primary">
            <Row className="mx-md-4">
                <h2>Felhasználók</h2>
            </Row>
            <Row className="mx-md-4">
                <Table data={data} columns={columns} />
            </Row>
        </div>
    );
}
